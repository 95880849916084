import install from './install'

/**
 * Source: https://legacydocs.hubspot.com/docs/methods/forms/submit_form
 *
 * @typedef HubspotFormErrorTypes
 * @enum {HubspotFormErrorTypes}
 */
export const HubspotFormErrorTypes = {
  INVALID_EMAIL: 'INVALID_EMAIL',
  BLOCKED_EMAIL: 'BLOCKED_EMAIL'
}

export default {
  install
}
