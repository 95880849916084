<template>
  <nuxt-link
    v-track-click
    :class="[colorVariant]"
    class="t-btn t-btn--link"
    :to="to"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <span class="t-btn__body">
      <slot />
    </span>
  </nuxt-link>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'
import TBtn from './TBtn'

export default {
  name: 'TBtnLink',

  extends: TBtn,

  mixins: [
    usesColor
  ],

  props: {
    to: {
      type: String,
      default: '/'
    }
  }
}
</script>
