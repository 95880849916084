<template>
  <div class="form-part">
    <h1 class="form-part__title pb-6">
      {{ title }}
    </h1>

    <div class="form-part__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbstractFormPart',

  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
