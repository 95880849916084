<template>
  <DatePick
    class="t-date-picker"
    :class="[colorVariant]"
    :has-input-element="false"
    v-bind="{...$attrs, ...$props}"
    :weekdays="weekdays"
    :months="months"
    :prev-month-caption="prevMonthCaption"
    :next-month-caption="nextMonthCaption"
    :set-time-caption="setTimeCaption"
    v-on="$listeners"
  />
</template>

<script>
import DatePick from 'vue-date-pick'
import usesColor from 'chimera/all/mixins/usesColor'

/**
 * Wrapper for Vue Date Pick
 *
 * @see https://dbrekalo.github.io/vue-date-pick/
 */
export default {
  name: 'TDatePicker',

  components: {
    DatePick
  },

  mixins: [
    usesColor
  ],

  props: {
    selectableYearRange: {
      type: Object,
      default: () => ({
        from: new Date().getFullYear(),
        to: new Date().getFullYear() + 2
      })
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      nextMonthCaption: this.$i18n.t('nextMonth'),
      prevMonthCaption: this.$i18n.t('prevMonth'),
      setTimeCaption: this.$i18n.t('setTime'),
      weekdays: [
        this.$i18n.t('abbrMonday'),
        this.$i18n.t('abbrTuesday'),
        this.$i18n.t('abbrWednesday'),
        this.$i18n.t('abbrThursday'),
        this.$i18n.t('abbrFriday'),
        this.$i18n.t('abbrSaturday'),
        this.$i18n.t('abbrSunday')
      ],
      months: [
        this.$i18n.t('january'),
        this.$i18n.t('february'),
        this.$i18n.t('march'),
        this.$i18n.t('april'),
        this.$i18n.t('may'),
        this.$i18n.t('june'),
        this.$i18n.t('july'),
        this.$i18n.t('august'),
        this.$i18n.t('september'),
        this.$i18n.t('october'),
        this.$i18n.t('november'),
        this.$i18n.t('december')
      ]
    }
  }
}
</script>

<i18n>
{
  "nl-NL":  {
    "nextMonth": "Volgende maand",
    "prevMonth": "Vorige maand",
    "setTime": "Zet tijd:",
    "abbrMonday": "Ma",
    "abbrTuesday": "Di",
    "abbrWednesday": "Wo",
    "abbrThursday": "Do",
    "abbrFriday": "Vr",
    "abbrSaturday": "Za",
    "abbrSunday": "Zo",
    "january": "Januari",
    "february": "Februari",
    "march": "Maart",
    "april": "April",
    "may": "Mei",
    "june": "Juni",
    "july": "Juli",
    "august": "Augustus",
    "september": "September",
    "october": "Oktober",
    "november": "november",
    "december": "December"
  },
  "nl-BE":  {
    "nextMonth": "Volgende maand",
    "prevMonth": "Vorige maand",
    "setTime": "Zet tijd:",
    "abbrMonday": "Ma",
    "abbrTuesday": "Di",
    "abbrWednesday": "Wo",
    "abbrThursday": "Do",
    "abbrFriday": "Vr",
    "abbrSaturday": "Za",
    "abbrSunday": "Zo",
    "january": "Januari",
    "february": "Februari",
    "march": "Maart",
    "april": "April",
    "may": "Mei",
    "june": "Juni",
    "july": "Juli",
    "august": "Augustus",
    "september": "September",
    "october": "Oktober",
    "november": "november",
    "december": "December"
  },
  "es-ES":  {
    "nextMonth": "Próximo mes",
    "prevMonth": "El mes pasado",
    "setTime": "Fijar tiempo:",
    "abbrMonday": "Lu",
    "abbrTuesday": "Ma",
    "abbrWednesday": "Mi",
    "abbrThursday": "Ju",
    "abbrFriday": "Vi",
    "abbrSaturday": "Sa",
    "abbrSunday": "Do",
    "january": "Enero",
    "february": "Febrero",
    "march": "Marzo",
    "april": "Abril",
    "may": "Mayo",
    "june": "Junio",
    "july": "Julio",
    "august": "Agosto",
    "september": "Septiembre",
    "october": "Octubre",
    "november": "Noviembre",
    "december": "Diciembre"
  },
  "de-DE":  {
    "nextMonth": "Nächster Monat",
    "prevMonth": "Letzter Monat",
    "setTime": "Zeit einstellen:",
    "abbrMonday": "Mo",
    "abbrTuesday": "Di",
    "abbrWednesday": "Mi",
    "abbrThursday": "Do",
    "abbrFriday": "Fr",
    "abbrSaturday": "Sa",
    "abbrSunday": "So",
    "january": "Januar",
    "february": "Februar",
    "march": "März",
    "april": "April",
    "may": "Mai",
    "june": "Juni",
    "july": "Juli",
    "august": "August",
    "september": "September",
    "october": "Oktober",
    "november": "November",
    "december": "Dezember"
  },
  "fr-BE":  {
    "nextMonth": "Le mois prochain",
    "prevMonth": "Le mois dernier",
    "setTime": "Régler le temps:",
    "abbrMonday": "Lun",
    "abbrTuesday": "Mar",
    "abbrWednesday": "Mer",
    "abbrThursday": "Jeu",
    "abbrFriday": "Ven",
    "abbrSaturday": "Sam",
    "abbrSunday": "Dim",
    "january": "Janvier",
    "february": "Février",
    "march": "Mmars",
    "april": "Avril",
    "may": "Mai",
    "june": "Juin",
    "july": "Juillet",
    "august": "Août",
    "september": "Septembre",
    "october": "Octobre",
    "november": "Novembre",
    "december": "Décembre"
  },
  "fr-FR":  {
    "nextMonth": "Le mois prochain",
    "prevMonth": "Le mois dernier",
    "setTime": "Régler le temps:",
    "abbrMonday": "Lun",
    "abbrTuesday": "Mar",
    "abbrWednesday": "Mer",
    "abbrThursday": "Jeu",
    "abbrFriday": "Ven",
    "abbrSaturday": "Sam",
    "abbrSunday": "Dim",
    "january": "Janvier",
    "february": "Février",
    "march": "Mmars",
    "april": "Avril",
    "may": "Mai",
    "june": "Juin",
    "july": "Juillet",
    "august": "Août",
    "september": "Septembre",
    "october": "Octobre",
    "november": "Novembre",
    "december": "Décembre"
  }
}
</i18n>
