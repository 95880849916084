<template>
  <div
    class="t-alert"
    :class="[colorVariant]"
    role="alert"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <span v-if="icon" class="t-alert__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img">
        <path :d="icon" />
      </svg>
    </span>

    <div class="t-alert__wrapper">
      <strong v-if="title" class="t-alert__title">
        {{ title }}
      </strong>

      <span v-if="body" class="t-alert__body">
        {{ body }}
      </span>
    </div>
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TAlert',

  mixins: [
    usesColor
  ],

  props: {
    title: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    body: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'warning'
    }
  }
}
</script>
