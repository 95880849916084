import { bus } from 'chimera/all/plugins/eventbus/bus'

/**
 * Binds a callback to a specific event happening.
 *
 * @param {string} eventName
 * @param {object} event
 */
export function emit (eventName, event) {
  bus.$emit(eventName, event)
}
