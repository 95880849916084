// NL
export const drivingSchoolLicenseANL = {
  id: 'c7c406e2-91be-4ba9-8eb2-4dec71923ae3',
  identifier: 'driving-school-license-a'
}
export const drivingSchoolLicenseBNL = {
  id: 'bdb7e7b3-1b46-4a9f-8915-9312494489c6',
  identifier: 'driving-school-license-b'
}
export const drivingSchoolLicenseBENL = {
  id: '91f08b93-9e36-4eee-b5d6-6f630b981db7',
  identifier: 'driving-school-license-be'
}
export const drivingSchoolLicenseCCEDDENL = {
  id: '7c42f458-d909-46bc-a931-62113977da28',
  identifier: 'driving-school-license-c-ce-d-de'
}
