import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.level
 */
export function trackConsent (Vue, { scope, level }) {
  Vue.$gtag.event('consent', createScopedEvent(scope, {
    level
  }))
}
