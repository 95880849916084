import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.who
 * @param {string} event.what
 * @param {string} event.value
 * @example {
 *   who: CookiePageLink,
 *   what: undefined|on|off,
 *   scope: scopeObject
 * }
 */
export function trackClick (Vue, { scope, who, what = '', value } = {}) {
  // Assert that our event is received correctly.
  if (who === undefined || what === undefined) {
    throw new TypeError(`Received click event with who ${who}, what ${what}`)
  }
  Vue.$gtag.event('click', createScopedEvent(scope, {
    element_id: who,
    element_type: what,
    ...(value && { element_value: value })
  }))
}
