<template>
  <table class="t-table">
    <slot />
  </table>
</template>

<script>
export default {
  name: 'TTable'
}
</script>
