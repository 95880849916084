/**
 * Add global debounce function
 *
 * @param {Function} fn
 * @param {number} time
 * @returns {Function}
 */
export function debounce (fn, time) {
  let timeout

  return function () {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}
