<template>
  <div>
    <NavigationBar v-bind="{...$attrs, ...$props}">
      <slot name="default" />
    </NavigationBar>

    <NavigationBar v-bind="{...$attrs, ...$props}" id="navbar-dropdown" class="navbar-dropdown">
      <button class="navbar-dropdown__hamburger" @click="closed = !closed">
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 8h24M4 16h24M4 24h24" />
        </svg>
      </button>

      <div class="navbar-dropdown__body" :class="{ 'is-closed' : closed }">
        <slot name="body" />
      </div>
    </NavigationBar>
  </div>
</template>

<script>
import NavigationBar from './NavigationBar'

export default {
  name: 'DropdownNavigationBar',

  components: {
    NavigationBar
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      closed: true
    }
  },

  /**
   */
  mounted () {
    this.scrollFunction()

    window.addEventListener('scroll', () => {
      this.scrollFunction()
    })
  },

  methods: {
    /**
     * Apply classes on user scroll
     */
    scrollFunction () {
      const navbar = document.getElementById('navbar-dropdown')
      if (!navbar) {
        return
      }

      if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
        navbar.classList.add('navbar__fixed')
      } else {
        navbar.classList.remove('navbar__fixed')
        this.closed = true
      }
    }
  }
}
</script>
