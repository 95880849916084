/**
 * @param {object} Vue
 * @param {object} event
 * @param {boolean} event.hadErrors
 */
export function trackNextPageAfterError (Vue, { hadErrors }) {
  if (!hadErrors) {
    return
  }

  Vue.prototype.$absmartly.track('next-page-after-error')
}
