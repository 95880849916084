<template>
  <div />
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'

export default {
  name: 'AbstractTSelectable',

  mixins: [
    usesColor
  ],

  props: {
    id: {
      type: String,
      required: true
    },

    value: {
      type: String,
      default: undefined
    },

    label: {
      type: String,
      required: true
    },

    /**
     * Options that will be used to configure the selectable
     *
     * @example {
     *   component: TSelectableWithTextField,
     *   tooltip: 'This text will be used for the tooltip',
     *   textField: {
     *     type: 'number'
     *     prefix: '$'
     *   }
     * }
     */
    options: {
      type: Object,
      default: () => {}
    },

    isCheckbox: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      selectedIcon: undefined,
      unselectedIcon: undefined,
      type: undefined,
      role: undefined
    }
  },

  /**
   */
  created () {
    this.selectedIcon = this.isCheckbox ? mdiCheckboxMarked : mdiRadioboxMarked
    this.unselectedIcon = this.isCheckbox ? mdiCheckboxBlankOutline : mdiRadioboxBlank
    this.role = this.isCheckbox ? 'checkbox' : 'radio'
    this.type = this.isCheckbox ? 'checkbox' : 'radio'
  },

  methods: {
    /**
     */
    onClick () {
      this.emitSelectionEvent()
      this.emitValueEvent()
    },

    /**
     * This event is used to expose the value of the selectable
     */
    emitSelectionEvent () {
      this.$emit('selected', { id: this.id, value: this.value })
    },

    /**
     * This event is used to expose the value of the selectable
     *
     * @param value
     */
    emitValueEvent (value = this.value) {
      this.$emit('change', { id: this.id, value })
    },

    /**
     */
    trackSelectable () {
      let context = 'radio'
      if (this.isCheckbox) {
        context = this.isSelected ? 'on' : 'off'
      }

      this.$eventBus.emitClickEvent(this.id, context)
    }
  }
}
</script>
