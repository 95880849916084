// NL - Consumer
export const homeBatteryConsumerNL = {
  id: '9c78e8ae-4397-4fd6-bf19-a7348f6ef2a5',
  identifier: 'home-battery-consumer'
}

// NL - Corporate
export const homeBatteryCorporateNL = {
  id: '9c78e8ae-439b-4ff1-89e4-c074bf15c20d',
  identifier: 'home-battery-corporate'
}
