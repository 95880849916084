import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * @param {object} experiment
 */
export function addExperimentVariantClassToBody (experiment) {
  const experimentCodeMatch = experiment && experiment.id ? experiment.id.match(/PD-(\S+)/) : null
  const experimentCode = experimentCodeMatch && experimentCodeMatch[0] ? experimentCodeMatch[0] : undefined

  if (experimentCode) {
    document.body.classList.add(`${experimentCode}__${experiment.isTreatment ? 'variant' : 'control'}`)
  }
}

export default () => {
  /**
   * Add the experiment result as class to the body.
   */
  EventBus.onExperimentSetEvent(({ experiment }) => {
    addExperimentVariantClassToBody(experiment)
  })
}
