<template>
  <span class="t-icon" :class="[colorVariant]">
    <svg
      class="t-icon__svg"
      :width="width || size"
      :height="height || size"
      :viewBox="viewBox"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path :d="icon" />
    </svg>
  </span>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TIcon',

  mixins: [
    usesColor
  ],

  props: {
    width: {
      type: [Number, String],
      default: null
    },

    height: {
      type: [Number, String],
      default: null
    },

    size: {
      type: [Number, String],
      default: 24
    },

    viewBox: {
      type: [String],
      default: '0 0 24 24'
    },

    icon: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: ''
    }
  }
}
</script>
