import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Happens when our leadr back-end is unable to find a postal code.
 *
 * @type {string}
 */
export const postalNotFoundEvent = 'eb_postalNotFound'

/**
 * @param {string} country
 * @param {string} postal
 */
export function emitPostalNotFoundEvent (country, postal) {
  const scope = getEventScope()
  emitPostalNotFoundEventInScope(scope, country, postal)
}

/**
 * @param {object} scope
 * @param {string} country
 * @param {string} postal
 */
export function emitPostalNotFoundEventInScope (scope, country, postal) {
  emit(postalNotFoundEvent, {
    country,
    postal,
    scope
  })
}

/**
 * @param {Function} callback
 */
export function onPostalNotFoundEvent (callback) {
  bindOn(postalNotFoundEvent, callback)
}

/**
 * Happens when our postal code api is unable to find detailed information on a postal code.
 *
 * @type {string}
 */
export const postalNotAutoCompletedEvent = 'eb_postalNotAutoCompleted'

/**
 * @param {string} country
 * @param {string} postal
 */
export function emitPostalNotAutoCompletedEvent (country, postal) {
  const scope = getEventScope()
  emitPostalNotAutoCompletedEventInScope(scope, country, postal)
}

/**
 * @param {object} scope
 * @param {string} country
 * @param {string} postal
 */
export function emitPostalNotAutoCompletedEventInScope (scope, country, postal) {
  emit(postalNotAutoCompletedEvent, {
    country,
    postal,
    scope: getEventScope()
  })
}

/**
 * @param {Function} callback
 */
export function onPostalNotAutoCompletedEvent (callback) {
  bindOn(postalNotAutoCompletedEvent, callback)
}
