import { bindEvents } from 'chimera/all/plugins/tracking/google/bind'

/**
 * Default parameters used for both the concept analytics account
 * as the roll-up account.
 *
 * @type {object}
 */
const defaultParams = {
  anonymize_ip: true,
  send_page_view: false
}

/**
 * @returns {object}
 */
export const getDefaultParams = () => {
  // The GA Debug Mode is set up with two elements:
  // 1) Setting the debug_mode
  // 2a) Preventing the filter to block the non-production traffic. All traffic with traffic_type 'internal' is blocked, changing this to another value will allow it.
  // 2b) In the Google Analytics property a Developer Traffic filter is active that make sure that the allowed debug traffic is not added to the regular data.
  const enableGADebugMode = process.env.environment !== 'production'
  return {
    ...defaultParams,
    ...(enableGADebugMode && { debug_mode: true }),
    ...(enableGADebugMode && { traffic_type: 'internal_debug_mode' })
  }
}

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  // Merge options with defaults
  const { gaConceptId, gaRollupId, gAdsId } = (options = {
    ...options,
    ..._options
  })

  window.dataLayer = window.dataLayer || []

  // Bind functions
  bindFunctions(Vue)

  // Bind events
  bindEvents(Vue)

  initialise(gaConceptId, gaRollupId, gAdsId)
}

/**
 *
 * @param {string} conceptId
 * @param {string} rollupId
 * @param {string} gAdsId
 */
function initialise (conceptId, rollupId, gAdsId) {
  gtag('js', new Date())

  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    wait_for_update: 2000
  })

  gtag('set', 'ads_data_redaction', true)

  gtag('config', conceptId, { ...getDefaultParams() })
  gtag('config', rollupId, { ...getDefaultParams() })
  gtag('config', gAdsId)
}

/**
 * @param {object} Vue
 */
export function bindFunctions (Vue) {
  Vue.$gtag = {}
  Vue.$gtag.event = (...args) => gtag('event', ...args)
  Vue.$gtag.set = (...args) => gtag('set', ...args)
  Vue.$gtag.pageView = (...args) => gtag('event', 'page_view', ...args)

  const { gAdsId, gAdsConversionAction } = getOptions()
  Vue.$googleAds = {}
  Vue.$googleAds.options = {
    gAdsId,
    gAdsConversionAction
  }

  Vue.prototype.$gtag = Vue.$gtag
  Vue.prototype.$googleAds = Vue.$googleAds
}

/**
 * Google Analytics gtag function.
 */
export function gtag () {
  window.dataLayer.push(arguments)
}
