import {
  trackConversionGoal,
  trackEnhancedConversionGoal,
  trackPageView
} from 'chimera/all/plugins/tracking/microsoft/events'

/**
 * Our event handling for Microsoft Ads UET.
 *
 * @param {object} Vue
 * @param {object} options
 * @param {Function} options.enqueueEvent
 */
export function bindEvents (Vue, { enqueueEvent }) {
  // Track page view events.
  Vue.$eventBus.onPageViewEvent((event) => {
    enqueueEvent('onPageViewEvent', () => trackPageView(event))
  })

  // On transaction event we track conversion goal.
  Vue.$eventBus.onTransactionEvent((event) => {
    enqueueEvent('onTransactionEvent', () => trackConversionGoal(event))
  })

  // On transaction event we track enhanced conversion goal.
  Vue.$eventBus.onceOnEnhancedConversionEvent((event) => {
    enqueueEvent('onceOnEnhancedConversionEvent', () =>
      trackEnhancedConversionGoal(event)
    )
  })
}
