<template>
  <div
    class="t-stepper"
    :class="[colorVariant]"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <div class="t-stepper__top-container">
      <div class="t-stepper__top">
        <t-stepper-header
          v-for="(step, index) in steps"
          :key="index"
          :is-active="activeStep === index"
          :step="step"
          :index="index + 1"
          :color="color"
          @mouseover="setActiveStep(index)"
        />
      </div>
      <span class="t-stepper__top-line" />
    </div>

    <div class="t-stepper__body">
      <t-card
        v-for="(step, index) in steps"
        v-show="activeStep === index"
        :key="step[index]"
      >
        <div class="t-card__step">
          Stap {{ index + 1 }}
        </div>
        <span class="t-card__title">
          {{ step.title }}
        </span>
        <span class="t-card__text">
          {{ step.text }}
        </span>
      </t-card>
    </div>
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TStepper',

  mixins: [
    usesColor
  ],

  props: {
    steps: {
      type: Array,
      default: () => [],
      required: true
    },

    color: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    activeStep: 0
  }),

  methods: {
    /**
     * @param {string} index
     */
    setActiveStep (index) {
      if (index >= 0 && index < this.steps.length) {
        this.activeStep = index
      }
    }
  }
}
</script>
