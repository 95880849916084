import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {object} event.event
 */
export function trackConversion (Vue, { scope, event } = {}) {
  Vue.$gtag.event('conversion', createScopedEvent(scope, event))
}

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {number} event.revenueMade
 * @param {object} event.service
 */
export function trackLeadConversion (Vue, { scope, revenueMade, service }) {
  if (revenueMade === undefined || service === undefined) {
    const serviceIdentifier = !service || !service.identifier ? 'unknown-service-identifier' : service.identifier
    throw new TypeError(`Received transaction event with, revenueMade: ${revenueMade} service: ${serviceIdentifier}`)
  }

  trackConversion(Vue, {
    scope,
    event: {
      type: 'lead',
      service: service.identifier,
      value: revenueMade === 0 ? 0.01 : revenueMade
    }
  })
}
