<template>
  <div
    class="t-textarea"
    :class="[
      { 't-textarea--show-icons' : !hideIcons},
      { 't-textarea--dense' : dense},
      { 't-textarea--focused' : isFocused},
      validity,
      colorVariant
    ]"
  >
    <label v-if="label" class="t-text-field__label" :for="$attrs.id">
      {{ label }}
    </label>

    <div class="t-textarea__wrapper">
      <div v-if="prefix" class="t-textarea__prefix">
        {{ prefix }}
      </div>

      <div v-if="prefixIcon" class="t-textarea__prefix-icon">
        <t-icon :icon="prefixIcon" />
      </div>

      <textarea
        class="t-textarea__input"
        v-bind="{...$props, ...$attrs}"
        :placeholder="placeholder || $attrs.placeholder"
        @input="$emit('input', $event.target.value)"
        @blur="onBlur"
        @focus="onFocus"
      />

      <span class="t-text_field__state-icon" />
    </div>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TTextarea',

  components: {
    FormErrorMessages
  },

  mixins: [
    usesColor
  ],

  inheritAttrs: false,

  props: {
    hideIcons: {
      type: Boolean,
      default: false
    },

    dense: {
      type: Boolean,
      default: false
    },

    prefix: {
      type: String,
      default: undefined
    },

    prefixIcon: {
      type: String,
      default: undefined
    },

    success: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: ''
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      isFocused: false
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    validity () {
      if (this.errorMessages.length > 0) {
        return 't-textarea--error'
      }

      if (this.success) {
        return 't-textarea--success'
      }

      return ''
    }
  },

  methods: {
    /**
     * @param {Event} event
     */
    onBlur (event) {
      this.isFocused = false
      this.$emit('blur', event.target.value)
    },

    /**
     * @param {Event} event
     */
    onFocus (event) {
      this.isFocused = true
      this.$emit('focus', event.target.value)
    }
  }
}
</script>
