// NL
export const gardenerDesignAndConstruction = {
  id: '74158c65-7700-42d9-887b-9fa0b4e130a6',
  identifier: 'gardener-design-and-construction'
}

export const gardenerMaintenanceAndRenovation = {
  id: '2ab69c47-ff89-4582-b574-749660654145',
  identifier: 'gardener-maintenance-and-renovation'
}

export const gardenerPavement = {
  id: '850e4e73-3e90-4f3d-9f39-6266958e861f',
  identifier: 'gardener-pavement'
}
