import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

/**
 * User navigated within a form.
 *
 * @type {string}
 */

export const formNavigationEvent = 'eb_formNavigation'

/**
 * Emit a formNavigation event.
 *
 * @param {string} direction
 * @param {string} fromStepId
 * @param {string} toStepId
 */
export function emitFormNavigationEvent (direction, fromStepId, toStepId) {
  emit(formNavigationEvent, {
    direction,
    fromStepId,
    toStepId,
    scope: getEventScope()
  })
}

/**
 * @param {Function} callback
 */
export function onFormNavigationEvent (callback) {
  bindOn(formNavigationEvent, callback)
}
