<template>
  <div class="container form-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormContainer'
}
</script>
