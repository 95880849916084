import { hasConsentForServices, initializePluginWithConsent } from 'chimera/all/plugins/config/usercentrics.client'
import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const dataLayer = (window.dataLayer = window.dataLayer || [])
  const { gtmId, store } = (options = {
    ...options,
    ..._options
  })

  init(gtmId)

  const requiredServices = []

  const QUEUE_EVENT_KEY = 'google_tag_manager'
  const enqueueEvent = async (eventName, fn) =>
    (await hasConsentForServices(requiredServices))
      ? fn()
      : store.dispatch('queue/enqueue', {
        key: QUEUE_EVENT_KEY,
        eventName,
        func: fn
      })

  bindEvents(Vue, {
    dataLayer,
    enqueueEvent
  })

  initializePluginWithConsent(() => {
    store.dispatch('queue/flush', QUEUE_EVENT_KEY)
  }, requiredServices)
}

/**
 * @param {string}  gtmId
 */
function init (gtmId) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', gtmId)
}
