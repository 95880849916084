// NL - Consumer
export const paintingConsumerNL = {
  id: '278b223f-86cb-4061-8dda-628d47037b75',
  identifier: 'painting-consumer'
}

// NL - Corporate
export const paintingCorporateNL = {
  id: '27f6895c-ce87-48fa-84ed-574f310c0c22',
  identifier: 'painting-corporate'
}
