module.exports = {
  global: {
    'contact-us': 'Kontaktieren Sie uns',
    back: 'Zurück',
    continue: 'Weiter',
    privacyPolicy: 'Datenschutzerklärung',
    privacyPolicyLink: '/datenschutzerklarung',
    cookiePolicy: 'Cookies',
    cookiePolicyLink: '/cookies',
    termsAndConditions: 'Allgemeine geschäftsbedingungen',
    termsAndConditionsLink: '/allgemeine-geschaftsbedingungen',
    imprint: 'Impresssum',
    imprintLink: '/impressum',
    companyLink: 'https://www.bambelo.com/en/',
    receiveUpToSixQuotes:
      'Erhalten Sie bis zu 6 Angebote von Spezialisten in Ihrer Region und vergleichen Sie.',
    requiredFields: '@todo',
    other: 'Sonstige',
    yes: '@todo',
    no: '@todo'
  },

  validation: {
    required: 'Denken Sie daran?',
    invalid: 'Etwas scheint schief gelaufen zu sein.',
    blacklisted: 'Etwas scheint schief gelaufen zu sein.',
    invalidLength: 'Die Zeichenanzahl scheint nicht richtig zu sein.'
  },

  field: {
    postal: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
      validation: {
        invalid: 'Ihre Postleitzahl scheint nicht korrekt zu sein.',
        required: 'Sie haben vergessen, Ihre Postleitzahl einzugeben.',
        unknown: 'Diese Postleitzahl ist uns unbekannt.',
        incorrectFormat: '@todo'
      }
    },

    comments: {
      label: 'Sonstige Informationen',
      placeholder: 'Sonstige Informationen',
      validation: {
        invalid: '@todo',
        required: 'Möchten Sie unseren Spezialisten wirklich nichts mitteilen?'
      }
    },

    'address-city': {
      label: 'Wohnort',
      placeholder: 'Wohnort',
      validation: {
        invalid: 'Ihr Wohnort scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihre Wohnort einzugeben.'
      }
    },

    'address-country-name': {
      label: 'Land',
      placeholder: 'Land',
      validation: {
        invalid: 'Ihr Land scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihr Land einzugeben.'
      }
    },

    'address-floor-level': {
      label: 'Etage',
      placeholder: 'Etage',
      validation: {
        invalid: 'Ihr Etage scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihre Etage einzugeben.'
      }
    },

    'address-number': {
      label: 'Hausnummer',
      placeholder: 'Hausnummer',
      validation: {
        invalid: 'Ihre Hausnummer scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihre Hausnummer einzugeben.'
      }
    },

    'address-property-type': {
      label: 'Immobilientyp',
      placeholder: 'Immobilientyp',
      validation: {
        invalid: 'Ihr Eigenschaftstyp scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihren Immobilientyp einzugeben.'
      }
    },

    'address-street': {
      label: 'Anschrift',
      placeholder: 'Anschrift',
      validation: {
        invalid: 'Ihr Straßenname scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihren Straßennamen einzugeben.'
      }
    },

    'company-name': {
      label: 'Name der Firma',
      placeholder: 'Name der Firma',
      validation: {
        invalid: 'Ihr Firmenname scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihren Firmennamen einzugeben.'
      }
    },

    'dormer-width': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: 'Diese Dimension scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, die Dimension einzugeben.'
      }
    },

    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
      validation: {
        invalid: 'Ihre E-Mail-Adresse scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihre E-Mail-Adresse anzugeben.'
      }
    },

    name: {
      label: 'Name',
      placeholder: 'Name',
      validation: {
        invalid: 'Ihr Name scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihren Namen einzugeben.'
      }
    },

    phone: {
      label: 'Telefonnummer',
      placeholder: 'Telefonnummer',
      validation: {
        invalid: 'Ihre Telefonnummer scheint nicht richtig zu sein.',
        required: 'Sie haben vergessen, Ihre Telefonnummer einzugeben.'
      }
    },

    message: {
      label: 'Ihre Nachricht',
      placeholder: 'Ihre Nachricht',
      validation: {
        invalid: '@todo',
        required: 'Sie haben vergessen, Ihre Nachricht einzugeben.'
      }
    },

    'contact-name': {
      label: 'Kontaktperson',
      placeholder: 'Kontaktperson',
      validation: {
        invalid: '@todo',
        required: 'Sie haben vergessen, den Namen der Kontaktperson anzugeben.'
      }
    },

    'estimated-garden-area': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    'move-preferred-date': {
      validation: {
        invalid: '@todo',
        required: '@todo'
      },
      flexibleDate: '@todo',
      specificDate: '@todo'
    },

    'period-of-construction': {
      options: {
        'before-1975': '@todo',
        'between-1975-and-1992': '@todo',
        'after-1992': '@todo'
      }
    },

    'type-of-heating': {
      options: {
        'floor-heating': '@todo',
        'floor-heating-and-radiators': '@todo',
        radiators: '@todo'
      }
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': '@todo',
        'air-water-heat-pump': '@todo',
        'ground-water-heat-pump': '@todo',
        'water-water-heat-pump': '@todo',
        'hybrid-heat-pump': '@todo',
        unknown: '@todo'
      }
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': '@todo',
        'cavity-wall-insulation': '@todo',
        'floor-insulation': '@todo',
        'double-glazing': '@todo',
        'no-insulation': '@todo'
      }
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': '@todo',
        'regular-radiator': '@todo',
        'low-temperature-radiator-description': '@todo',
        'regular-radiator-description': '@todo'
      }
    },

    'intends-to-replace-radiator': {
      options: {
        description: '@todo'
      }
    },

    'application-type': '@todo',
    'business-premises': '@todo',
    'agricultural-enterprise': '@todo',
    'large-business-requests': '@todo',
    'residential-building': '@todo',
    'type-of-assignment': '@todo',
    'house-battery': '@todo',
    'house-battery-and-solar-panels': '@todo',
    'estimated-annual-electricity-consumption': '@todo',
    'data-incompleted-error-message': 'Einige Daten fehlen'
  },

  privacyPolicy: {
    title: 'Datenschutzerklärung',
    headDescription: 'Datenschutzerklärung für Website-Besucher',
    introduction:
      '{concept} legt großen Wert auf Ihre Privatsphäre. Aus diesem Grund möchten wir Ihnen offen und transparent darlegen, wie wir Ihre personenbezogenen Daten verarbeiten. In dieser Datenschutzerklärung erfahren Sie daher, wie Ihre personenbezogenen Daten verarbeitet und geschützt werden. Diese Datenschutzerklärung gilt für die Nutzung der Website und der darüber angebotenen Dienstleistungen von {concept}.',

    section1Title: 'Wer verarbeitet Ihre personenbezogenen Daten?',
    section1Body1:
      'Das niederländische Unternehmen Bambelo B.V. verarbeitet die personenbezogenen Daten, die Sie uns über eine unserer Websites zur Verfügung stellen, und ist nach dem geltenden Datenschutzrecht der Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten. Unsere Kontaktdaten sind:',
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: 'NL-4811 VB',
    companyAddressCity: 'Breda Niederlande',
    companyChamberOfCommerce:
      'Eingetragen bei der Handelskammer unter Nr.: 20111350',
    section1Body2:
      'Wenn Sie eine unserer Websites zur Anforderung von Angeboten nutzen, leiten wir Ihre Daten an diejenigen unserer Geschäftspartner weiter, die die von Ihnen festgelegten Kriterien erfüllen. Diese Geschäftspartner verarbeiten Ihre Daten entsprechend ihren eigenen Datenschutzbestimmungen.  Auf der Grundlage dieser Informationen werden diese Unternehmen sich per E-Mail oder telefonisch mit Ihnen in Verbindung setzen, um Sie über ihre Dienstleistungen und Preise zu informieren. Durch eine Anfrage über {concept} werden somit Daten bei {concept} selbst sowie bei den Unternehmen, die mit Ihrer Anfrage verknüpft sind, gespeichert. Unternehmen, an die Bambelo B.V. Ihre Anfrage weiterleitet, sind nach anwendbaren Rechtsvorschriften und einer Vereinbarung, die die Bambelo B.V. zu diesem Zweck mit diesen Unternehmen schließt, an die Bestimmungen dieser Vereinbarung und die Datenschutz-Grundverordnung (DSGVO) gebunden. Die Kontaktdaten dieser Parteien entnehmen Sie bitte den Angeboten, die Sie von ihnen erhalten. Beschließt ein Unternehmen, nicht auf die Angebotsanfrage einzugehen, ist dieses Unternehmen zur Vernichtung der von ihm empfangenen Daten verpflichtet.',

    section2Title: 'Wo speichern wir Ihre Daten?',
    section2Body1:
      'Ihre von uns erhobenen Daten werden grundsätzlich im Europäischen Wirtschaftsraum („EWR“) gespeichert. Wenn Daten von uns in ein Land außerhalb des EWR übertragen und dort verarbeitet werden, erfolgt dies in Übereinstimmung mit anwendbarem Recht sowie in der Regel auf der Grundlage von Standardvertragsklauseln und gegebenenfalls zusätzlichen Maßnahmen.\n',

    section3Title:
      'Welche Arten von personenbezogenen Daten verarbeiten wir und auf welcher Grundlage?',
    section3Body1:
      'Wir verarbeiten die folgenden Kategorien von personenbezogenen Daten:',
    section3List1Item1:
      'Kontaktdaten wie Namen, Anschrift, E-Mail-Adresse und Telefonnummer',
    section3List1Item2:
      'Zusätzliche Angaben, die für die Anfrage relevant sind, wie etwa:',
    section3List1Item2Sub1: 'Kategorie',
    section3List1Item2Sub2: 'Art der Anfrage',
    section3List1Item2Sub3: 'Preisklasse',
    section3List1Item2Sub4: 'Art der Wohnung',
    section3List1Item2Sub5: 'sonstige Informationen, die Sie selbst angeben',
    section3Body2:
      'Wir verarbeiten die oben genannten Daten auf den folgenden Rechtsgrundlagen:',
    section3List2Item1:
      'Wenn Sie Angebote über unsere Website anfordern: zur Erfüllung eines mit Ihnen geschlossenen Vertrags oder mit Ihrer Einwilligung',
    section3List2Item2:
      'Wenn Sie (über unsere Website) eine Frage stellen: mit Ihrer Einwilligung',
    section3List2Item3:
      'In anderen Fällen: zur Wahrung berechtigter Interessen, die wir im Rahmen unserer Geschäftstätigkeit haben, z. B. Kundenpflege, Verbesserung unserer Dienstleistungen, Sicherung und Zugänglichkeit der Website, Kontakt zu unseren Geschäftspartnern. Hierbei wägen wir stets zwischen diesen Interessen und Ihrer Privatsphäre ab und verarbeiten wir nur die Daten, die zu den festgelegten Zwecken verarbeitet werden müssen.',

    section4Title: 'Wie lange bewahren wir Ihre Daten auf?',
    section4Body:
      'Wir bewahren Ihre Daten für einen Zeitraum von höchstens sieben (7) Jahren auf. Dies tun wir einerseits zwecks Erfüllung unserer gesetzlichen Aufbewahrungsfristen und andererseits zwecks Beobachtung von Trends und zur Verbesserung unserer Dienstleistungen. Wenn Sie mitteilen, dass Sie unsere Dienste nicht mehr nutzen möchten bzw. dass Ihre Daten gelöscht werden sollen, prüfen wir, ob diese Aufbewahrungsfrist verkürzt werden kann und welche Daten wir löschen können.',

    section5Title: 'Wer hat Zugang zu Ihren Daten?',
    section5Body:
      'Die von Ihnen angegebenen personenbezogenen Daten dienen dem ordnungsgemäßen Funktionieren der von {concept} angebotenen Dienstleistungen. {concept} stellt den Geschäftspartnern nur solche personenbezogenen Daten zur Verfügung, die sie benötigen, um Ihnen auf Grundlage Ihrer Anfrage ein passendes Angebot zu unterbreiten.',

    section6Title: 'Wofür verwenden wir Ihre personenbezogenen Daten?',
    section6Body:
      'Wir verwenden Ihre Daten in erster Linie zur Einholung von Angeboten für das Produkt oder die Dienstleistung, das bzw. die Sie suchen. Darüber hinaus verwenden wir Ihre Daten zur Evaluierung, Weiterentwicklung und Verbesserung unserer Dienstleistungen, Produkte und Systeme. In diesem Zusammenhang analysieren wir beispielsweise, wie wir unsere Dienstleistungen benutzerfreundlicher gestalten können (z. B. durch die Anpassung der Benutzeroberfläche, um den Informationsfluss zu vereinfachen oder häufig genutzte Funktionen hervorzuheben) und wie wir unsere IT-Systeme zugunsten höherer Sicherheit verbessern können. Dazu analysieren wir die von uns erhobenen Daten in aggregierter Form. Darüber hinaus verwenden wir die Daten zur Entwicklung neuer Dienstleistungen und zur Verbesserung unseres Produktsortiments. Die Bereitstellung (und Verarbeitung) dieser Daten ist eine notwendige Voraussetzung für die Erfüllung des Vertrags bzw. zur Ausführung Ihrer Angebotsanfrage. Wenn Sie die Daten nicht bereitstellen, können wir Ihnen nicht oder nicht ausreichend behilflich sein.',

    section7Title: 'Welche Rechte haben Sie?',
    section7Right1Title: 'Recht auf Auskunft:',
    section7Right1:
      '{title} Sie haben das Recht, jederzeit Auskunft über Ihre von uns gespeicherten personenbezogenen Daten zu verlangen.',
    section7Right2Title: 'Recht auf Datenübertragbarkeit:',
    section7Right2:
      '{title} Wenn {concept} Ihre personenbezogenen Daten automatisiert auf der Grundlage Ihrer Einwilligung oder auf Grundlage eines Vertrags verarbeitet, haben Sie das Recht, dass Ihnen oder einer anderen Partei eine Kopie Ihrer Daten in einem strukturierten, gängigen und maschinenlesbaren Format bereitgestellt wird. Dies gilt nur für die personenbezogenen Daten, die Sie uns zur Verfügung gestellt haben.',
    section7Right3Title: 'Recht auf Berichtigung:',
    section7Right3:
      '{title} Sie haben das Recht auf Berichtigung Ihrer personenbezogenen Daten, wenn diese unrichtig sind. Dies schließt das Recht auf Vervollständigung unvollständiger personenbezogener Daten mit ein.',
    section7Right4Title: 'Recht auf Vergessenwerden:',
    section7Right4:
      '{title} Sie haben das Recht, von uns die Löschung der von {concept} verarbeiteten personenbezogenen Daten zu verlangen.',
    section7Right5Title:
      'Recht auf Einschränkung der Verarbeitung und Widerspruch gegen die Verarbeitung:',
    section7Right5:
      '{title} Sie können von uns verlangen, die Verarbeitung von Daten (vorübergehend) zu beschränken, beispielsweise um die Richtigkeit der Daten zu überprüfen. Sie haben auch das Recht, Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen. Widersprechen Sie der Verarbeitung zum Zwecke der Direktwerbung, so werden Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.',
    section7Right6Title: 'Recht auf Zurückziehen der Einwilligung:',
    section7Right6:
      '{title} Sofern wir Daten auf der Grundlage Ihrer Einwilligung verarbeiten, können Sie diese Einwilligung jederzeit zurückziehen. Dies berührt nicht die Rechtmäßigkeit der vor dem Zurückziehen erfolgten Verarbeitung.',
    section7Body:
      'Für alle Anträge auf Ausübung der oben genannten Rechte gilt, dass Sie innerhalb von einem (1) Monat eine Antwort auf Ihren Antrag erhalten. Abhängig von der Komplexität der Anträge und der Anzahl der Anträge kann diese Frist gegebenenfalls um weitere zwei (2) Monate verlängert werden. In diesem Fall informieren wir Sie innerhalb eines (1) Monats nach Eingang Ihres Antrags.',

    section8Title: 'Wie können Sie Ihre Rechte ausüben?',
    section8Body:
      'Wir nehmen den Datenschutz sehr ernst. Unser engagierter Kundenservice beantwortet Ihre Fragen zu den oben genannten Rechten und nimmt Ihre Beschwerden entgegen. Sie können unsere Mitarbeitenden jederzeit unter {email} erreichen.',

    section9Title:
      'Zusammenarbeit bei steuer- und strafrechtlichen Ermittlungen',
    section9Body:
      'Gegebenenfalls sind wir gesetzlich verpflichtet, Ihre Daten im Zusammenhang mit steuerlichen oder strafrechtlichen Ermittlungen weiterzugeben. In einem solchen Fall sind wir gezwungen, Ihre Daten weiterzugeben, werden uns aber im Rahmen der uns gesetzlich eingeräumten Möglichkeiten dagegen wehren.',

    section10Title: 'Datenschutzbeauftragter',
    section10Body:
      'Wir haben einen Datenschutzbeauftragten ernannt, um sicherzustellen, dass wir Ihre personenbezogenen Daten fortwährend offen, richtig und rechtmäßig verarbeiten. Unseren Datenschutzbeauftragten erreichen Sie per E-Mail an {email} (Betreff „FG“).',

    section11Title: 'Recht auf Beschwerde bei einer Aufsichtsbehörde',
    section11Body:
      'Bitte teilen Sie uns mit, falls Sie der Ansicht sind, dass {concept} Ihre personenbezogenen Daten nicht korrekt verarbeitet. Sie haben zudem das Recht, bei der niederländischen Datenschutzbehörde (Autoriteit Persoonsgegevens) eine Beschwerde gegen diese Verarbeitung personenbezogener Daten einzureichen. Bitte benutzen Sie dazu diesen {link}',
    section11BodyLinkText: 'Link.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Aktualisierung unserer Datenschutzerklärung',
    section12Body:
      'Gegebenenfalls werden wir unsere Datenschutzerklärung aktualisieren müssen. Die jeweils aktuelle Fassung der Datenschutzerklärung finden Sie auf unserer Website. Wir informieren über sämtliche wesentlichen Änderungen der Datenschutzerklärung, u. a. in Bezug auf den Zweck der Nutzung Ihrer personenbezogenen Daten, die Identität des Verantwortlichen oder Ihre Rechte.',

    version: 'Fassung 4.1 – 23. Februar 2022'
  },

  cookiePolicy: {
    headTitle: 'Cookie-Richtlinie',
    title: 'Cookies',
    whatIsCookies: {
      title: 'Was sind Cookies?',
      body: 'Cookies sind kleine Textinformationen, die beim Besuch unserer Website von Ihrem Browser auf Ihrem Computer gespeichert werden und die bei zukünftigen Besuchen wieder aufgerufen werden können. Wir verwenden auf dieser Website Cookies.'
    },
    whatIsCookieFunction: {
      title: 'Welche Funktion haben diese Cookies?',
      firstParagraph: 'Wir verwenden Cookies, um sicherzustellen, dass Sie beim Besuch unserer Website nicht ständig dieselben Informationen erhalten oder eingeben. Es gibt verschiedene Arten von Cookies. Als Anlage finden Sie eine Übersicht über alle verwendeten Cookies, die angezeigt werden, wer die Cookies platziert, den Namen des Cookies, den Zweck des Platzierens des Cookies, die Speicherdauer des Cookies und die Folgen einer Weigerung des Cookies.',
      secondParagraph: 'Sie können das Speichern von Cookies auf Ihrem Computer über Ihren Browser deaktivieren. Sie können auch benachrichtigt werden, wenn Sie ein Cookie erhalten. Anweisungen zum Anpassen der Browsereinstellungen finden Sie bei den meisten Browsern in der Symbolleiste unter „Hilfe“. Obwohl Sie als Besucher der Website die Website weiterhin besuchen können, ist es möglich, dass bei der Weigerung von Cookies bestimmte Dienstleistungen oder Elemente der Website nicht optimal funktionieren. Das Deaktivieren von Cookies schränkt die Nutzung unserer Website und Dienstleistungen ein.',
      thirdParagraph: 'Einige Tracking-Cookies werden von Drittanbietern platziert, die Ihnen über unsere Website unter anderem Werbung anzeigen. Sie können diese Cookies zentral über {link} löschen, damit sie nicht noch einmal durch einen Drittanbieter auf einer Website platziert werden.',
      url: 'https://www.youronlinechoices.com/de/praferenzmanagement'
    },
    whoIAm: {
      title: 'Wissen Cookies, wer ich bin?',
      body: 'Nein. Ein Cookie kennt weder Ihren Namen noch Ihre Adresse, Ihr Alter und andere persönliche Informationen. Sie merken sich nur Ihre Vorlieben und Interessen, basierend auf Ihrem Surfverhalten.'
    },
    listTitle: 'Liste der Cookies',
    adjustPreferences: 'Cookie-Einstellungen anpassen',
    version: 'Version'
  },

  termsAndConditions: {
    title: 'Allgemeine geschäftsbedingungen',
    headDescription: 'Allgemeine geschäftsbedingungen'
  }
}
