// NL - Consumer
export const solarPanelsPurchaseConsumerNL = {
  id: '8f831c1b-121b-4358-bde9-2dbc436312c4',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerNL = {
  id: '42ca6675-1fab-4e99-b642-7f78437a0c6c',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerNL = {
  id: '9c8dba70-596f-44f0-8a44-59d2a3ad7c6c',
  identifier: 'solar-panels-rent-consumer'
}

// NL - Corporate
export const solarPanelsPurchaseCorporateNL = {
  id: 'c8d4bc15-ee41-4c08-878b-331a23168698',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateNL = {
  id: '32f8dd4e-e65e-4f41-adda-1052a85a995e',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateNL = {
  id: '1cd398e3-768e-403c-bc01-8d0dbf48b7d4',
  identifier: 'solar-panels-rent-corporate'
}

// BE - Consumer
export const solarPanelsPurchaseConsumerBE = {
  id: 'fec764c2-194f-428f-8b4e-abf6f35b2be0',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerBE = {
  id: '4f21890e-458f-4e0c-ac12-26839b55ba20',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerBE = {
  id: '3a9e741b-8612-4ab7-b150-7b54532b17fe',
  identifier: 'solar-panels-rent-consumer'
}

// BE - Corporate
export const solarPanelsPurchaseCorporateBE = {
  id: '9cd7fefc-14ea-47bf-a223-d29e34a634c6',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateBE = {
  id: '483378dc-5b2f-4f84-aebf-1b220ac95e81',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateBE = {
  id: '29613a26-22e4-40bf-98ca-364daffbbc3f',
  identifier: 'solar-panels-rent-corporate'
}

// ES - Consumer
export const solarPanelsPurchaseConsumerES = {
  id: '2504a73f-0018-4bb2-b0bd-cafbf11f217e',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerES = {
  id: '92632c86-ceff-460a-964a-a9b76cabb435',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerES = {
  id: 'f477d592-55df-43de-a8de-5e4b2b3599bc',
  identifier: 'solar-panels-rent-consumer'
}

// ES - Corporate
export const solarPanelsPurchaseCorporateES = {
  id: 'accfb847-cf91-4a8f-aed3-2d47df1fe6d7',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateES = {
  id: 'ee22b565-200b-4684-a3eb-f90452034655',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateES = {
  id: '45e5a601-2b72-4940-8bef-7e0ec08a9155',
  identifier: 'solar-panels-rent-corporate'
}

// DE - Consumer
export const solarPanelsPurchaseConsumerDE = {
  id: '8de7eafe-67ad-4886-aa6c-96837c7eecef',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerDE = {
  id: 'c74ec4e7-8119-4abd-95d1-c06889ddc80a',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerDE = {
  id: 'd5caf139-01b5-434b-953f-ca8b55758753',
  identifier: 'solar-panels-rent-consumer'
}

// DE - Corporate
export const solarPanelsPurchaseCorporateDE = {
  id: 'd123bb0b-ba33-440a-ac16-46847c39c8b8',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateDE = {
  id: 'f4f98b4b-0da3-4232-bdfb-c13303bc7615',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateDE = {
  id: '5be66d19-524f-477f-b594-eb3ecbcbb256',
  identifier: 'solar-panels-rent-corporate'
}

// FR - Consumer
export const solarPanelsPurchaseConsumerFR = {
  id: '30f9db09-8198-42e7-8cde-f1c62c7d810f',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerFR = {
  id: 'c4b66b0f-e930-4cd5-a259-bc7607da3f3f',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerFR = {
  id: '7863fa4a-a6ad-4bd2-aa13-5d8e7a00bff1',
  identifier: 'solar-panels-rent-consumer'
}

// FR - Corporate
export const solarPanelsPurchaseCorporateFR = {
  id: '9675d340-52f5-44f0-ab1a-4c6227282d94',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateFR = {
  id: 'a4caa7b1-0a14-41eb-9a18-7f4005d99a87',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateFR = {
  id: '8cb212fe-1c5e-449a-8a2d-2693aa5a676c',
  identifier: 'solar-panels-rent-corporate'
}

// IT - Consumer
export const solarPanelsPurchaseConsumerIT = {
  id: '6107c501-fca3-4387-8ad1-e5af2d410047',
  identifier: 'solar-panels-purchase-consumer'
}
export const solarPanelsPurchaseSolarCollectorsConsumerIT = {
  id: '7356a6ad-825b-4b96-ac89-edf253fe910d',
  identifier: 'solar-panels-purchase-solar-collectors-consumer'
}
export const solarPanelsRentConsumerIT = {
  id: '8c662b9b-a8ad-403d-b850-bd89f681fdb7',
  identifier: 'solar-panels-rent-consumer'
}

// IT - Corporate
export const solarPanelsPurchaseCorporateIT = {
  id: '3a1e29bf-b806-4deb-b07f-66160761cc39',
  identifier: 'solar-panels-purchase-corporate'
}
export const solarPanelsPurchaseSolarCollectorsCorporateIT = {
  id: 'd4531d78-4288-42d6-a707-f4de64b8a98f',
  identifier: 'solar-panels-purchase-solar-collectors-corporate'
}
export const solarPanelsRentCorporateIT = {
  id: '07831bb0-aac7-43b2-af36-dc54c0616dcb',
  identifier: 'solar-panels-rent-corporate'
}
