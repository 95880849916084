var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t-dialog__container"},[_c('Transition',{attrs:{"name":_vm.transition}},[_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"t-dialog",class:[
        {
          't-dialog--closed': !_vm.show,
          't-dialog--open': _vm.show,
          't-dialog--fullscreen-mobile': _vm.fullscreenMobile,
          't-dialog--fullscreen-desktop': _vm.fullscreenDesktop,
          't-dialog--scrollable': _vm.scrollable
        },
        _vm.colorVariant
      ]},'div',{..._vm.$props, ..._vm.$attrs},false),_vm.$listeners),[_c('t-dialog-card',{attrs:{"close-button-text":_vm.closeButtonText,"color":_vm.color},on:{"close":_vm.onClose}},[_vm._t("default")],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }