<template>
  <div id="app" class="layout-form bg-backdrop">
    <DropdownNavigationBar />

    <main>
      <Header>
        <FormContainer>
          <Nuxt />
        </FormContainer>
      </Header>
    </main>

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import DropdownNavigationBar from 'chimera/all/themes/blueflow/components/layout/DropdownNavigationBar'
import FormContainer from 'chimera/all/components/form/FormContainer'
import Footer from '~/components/layout/Footer'

export default {
  name: 'FormLayout',

  components: {
    Header,
    FormContainer,
    Footer,
    DropdownNavigationBar
  }
}
</script>
