<template>
  <nav class="navbar">
    <div class="navbar__container">
      <BackToLandingPageLink id="header-logo-link">
        <InlineSvg class="navbar__logo" :src="logo" alt="" />
      </BackToLandingPageLink>
      <slot />
    </div>
  </nav>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import BackToLandingPageLink from 'chimera/all/themes/blueflow/components/layout/BackToLandingPageLink'

export default {
  name: 'NavigationBar',

  components: {
    BackToLandingPageLink,
    InlineSvg
  },

  props: {
    logo: {
      type: String,
      default: 'images/logo.svg'
    }
  }
}
</script>
