<template>
  <div />
</template>

<script>
import phoneParser from 'chimera/all/functions/phoneParser'
import { EventBus } from 'chimera/all/plugins/eventbus'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

export default {
  name: 'AbstractFormStep',

  props: {
    autofocus: {
      type: Boolean,
      default: false
    },

    progressValue: {
      type: Number,
      default: undefined,
      required: true
    }
  },

  data: () => ({
    validFields: new Set(),
    invalidFields: new Set(),
    fromSubmit: false,
    parts: new Set(),
    partNames: new Set(),
    willTransitionOnValid: false,
    errorMessages: [],
    hadErrors: false,
    enhancedConversionFields: {},
    isSubmitting: false
  }),

  watch: {
    /**
     * @param {number} value
     */
    progressValue (value) {
      this.updateProgressValue(value)
    }
  },

  /**
   */
  mounted () {
    this.updateFields()
    this.updateProgressValue(this.progressValue)
  },

  methods: {
    /**
     * @returns {boolean}
     */
    isValid () {
      return this.areSetsEqual(this.validFields, this.partNames)
    },

    /**
     * @param {object} nextPage
     */
    routeTo (nextPage) {
      const { pathname: from } = getEventScope()
      const to = this.getPathForPageComponent(nextPage)
      const context = {
        from,
        to,
        hadErrors: this.hadErrors
      }

      this.beforeTransition(context)
      this.$router.push({ path: to })
      this.afterTransition(context)
    },

    /**
     * @param {object} page
     * @returns {string}
     */
    getPathForPageComponent (page) {
      return page.data().path || ''
    },

    /**
     * Called when a form part is changed.
     *
     * @param {object} change
     * @param {string} change.field
     * @param {string} change.value
     */
    onChange ({ field, value } = {}) {
      // Hidden parts are never manually changed and therefor shouldn’t interrupt the users intention to continue to the next step.
      const currentPart = this.getVueComponentForField(field)
      if (currentPart && currentPart.hasAttribute('hidden')) {
        return
      }

      // Disable willTransitionOnValid until user presses submit/enter again.
      this.willTransitionOnValid = false
    },

    /**
     * Used with result events emitted by form parts.
     *
     * @param {object} obj
     * @param {string} obj.field
     * @param {boolean} obj.isValid
     * @param {string} obj.value
     * @param {Array} obj.errors
     * @param {boolean} obj.isOptional
     */
    onResult ({ field, isValid, value, errors, isOptional } = {}) {
      if (!this.hadErrors) {
        this.hadErrors = errors && errors.length > 0
      }

      this.validFields = new Set(
        [...this.validFields].filter(x => this.partNames.has(x))
      )

      this.invalidFields = new Set(
        [...this.partNames].filter(
          x => !this.validFields.has(x) && isValid === false
        )
      )

      if (isValid) {
        this.errorMessages = []
        this.addValidField(field)
      } else {
        this.errorMessages = errors
        this.removeInvalidField(field)
      }

      if (
        this.canEventTrackEnhancedConversionEventBeTriggered(
          field,
          value,
          isValid
        )
      ) {
        this.trackEnhancedConversionEvent(this.enhancedConversionFields)
      }

      if (this.canEventTrackFormErrorBeTriggered(field, isValid, isOptional)) {
        this.trackFormErrorEvent()

        this.fromSubmit = false
      }

      // Perform validation to check whether transition should happen
      if (this.willTransitionOnValid && this.isValid()) {
        this.transition()
      }
    },

    /**
     * @param {string} field
     * @param {boolean} fieldIsValid
     * @param {boolean} fieldIsOptional
     * @returns {boolean}
     */
    canEventTrackFormErrorBeTriggered (field, fieldIsValid, fieldIsOptional) {
      if (this.isValid()) {
        return false
      }

      if (!this.fromSubmit) {
        return false
      }

      if (fieldIsValid) {
        return false
      }

      if (fieldIsOptional) {
        return false
      }

      return this.isLastFieldOfForm(field)
    },

    /**
     * @param {string} field
     * @param {string} value
     * @param {boolean} fieldIsValid
     * @returns {boolean}
     */
    canEventTrackEnhancedConversionEventBeTriggered (
      field,
      value,
      fieldIsValid
    ) {
      if (fieldIsValid === false) {
        return false
      }

      if (field !== 'email' && field !== 'phone') {
        return false
      }

      this.enhancedConversionFields[field] = value
      if (field === 'phone') {
        this.enhancedConversionFields.phone = phoneParser(
          value,
          this.$store.getters['context/get']('country')
        ).number
      }

      return !!(
        this.enhancedConversionFields?.email &&
        this.enhancedConversionFields?.phone
      )
    },

    /**
     * @param {string} field
     * @returns {boolean}
     */
    isLastFieldOfForm (field) {
      // In case we are in the last step of the form and there are only one field in current step...
      if (this.partNames?.has('submit') && this.invalidFields?.size === 1) {
        return this.invalidFields?.has(field)
      }

      // In case we are in the last step of the form and there are more than one field in current step...
      if (this.partNames?.has('submit') && this.invalidFields?.size > 1) {
        return (
          this.invalidFields?.has(field) &&
          [...this.invalidFields]?.indexOf(field) ===
            this.invalidFields?.size - 2
        )
      }

      // In case we are in the middle of the form...
      return (
        this.invalidFields?.has(field) &&
        [...this.invalidFields]?.indexOf(field) === this.invalidFields?.size - 1
      )
    },

    /**
     * @param {object} context
     */
    beforeTransition (context) {
      this.willTransitionOnValid = false
    },

    /**
     * @param {object} context
     */
    afterTransition (context) {
      EventBus.emitTransitionEvent(context)
    },

    /**
     * @param {string} field
     */
    addValidField (field) {
      this.validFields = new Set([...this.validFields, field])

      this.invalidFields.delete(field)
      this.invalidFields = new Set([...this.invalidFields])
    },

    /**
     * @param {string} field
     */
    removeInvalidField (field) {
      this.validFields.delete(field)
      this.validFields = new Set([...this.validFields])

      this.invalidFields = new Set([...this.invalidFields, field])
    },

    /**
     * Execute transition if step is valid
     */
    validate () {
      if (this.isValid()) {
        // Hide errors when step is valid.
        this.transition()
      } else {
        const submitButton = document.querySelector('#footerNextButton')

        if (submitButton !== null) {
          submitButton.disabled = false
          submitButton.classList.remove('opacity-50')
          submitButton.classList.remove('cursor-not-allowed')
        }

        this.isSubmitting = false

        // Trigger validate for invalid parts
        this.validateInvalidParts(this.parts)
      }
    },

    /**
     * @returns {void}
     */
    trackFormErrorEvent () {
      EventBus.emitFormSubmitErrorEvent(getEventScope())
    },

    /**
     * @param {object} fields
     * @param {string} fields.em
     * @param {string} fields.ph
     * @returns {void}
     */
    trackEnhancedConversionEvent (fields) {
      EventBus.emitEnhancedConversionEvent(fields)
    },

    /**
     * On form submit (by 'enter' key or submit button)
     * Mark validation to transition immediately on valid result.
     */
    onSubmit () {
      if (this.isSubmitting) {
        return
      }

      this.isSubmitting = true

      const submitButton = document.querySelector('#footerNextButton')

      if (submitButton !== null) {
        submitButton.disabled = true
        submitButton.classList.add('opacity-50')
        submitButton.classList.add('cursor-not-allowed')
      }

      this.willTransitionOnValid = true
      this.fromSubmit = true
      this.updateFields()
      this.validate()
    },

    /**
     * @param {Set<any>} parts
     */
    validateInvalidParts (parts) {
      for (const component of parts) {
        if (!this.validFields.has(component.$props.field)) {
          component.validate()
        }
      }
    },

    /**
     * @param children
     */
    collectFields (children) {
      const components = children.filter((child) => {
        // Filter all fields from children
        if (child.$props && child.$props.field !== undefined) {
          return true
        }

        // If the child has children, recursive find new fields
        if (child.$children !== undefined) {
          this.collectFields(child.$children)
        }

        return false
      })
      components.forEach(component => this.parts.add(component))
      components.forEach(component =>
        this.partNames.add(component.$props.field)
      )
    },

    /**
     * Wrapper function for collectFields.
     */
    updateFields () {
      if (!this.$refs.form) {
        return
      }

      // First we clear current parts and partNames.
      Object.assign(this.parts, new Set())
      Object.assign(this.partNames, new Set())

      // Then we collect all fields present in the form
      this.collectFields(this.$refs.form.$children)
    },

    /**
     * @param {number} value
     */
    updateProgressValue (value) {
      this.$store.dispatch('progressbar/add', { key: 'progress', value })
    },

    /**
     * Helper method to compare Sets
     *
     * @returns {boolean}
     * @param {Set} a
     * @param {Set} b
     */
    areSetsEqual (a, b) {
      return a.size === b.size && [...a].every(value => b.has(value))
    },

    /**
     * Return VueComponent for given field
     *
     * @param {string} field
     * @returns {any}
     */
    getVueComponentForField (field) {
      return [...this.parts].find((part) => {
        return part.field === field
      })
    }
  }
}
</script>
