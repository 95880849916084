// NL - Consumer
export const windowFramesPlasticConsumerNL = {
  id: 'a9100268-7a64-46da-bbb7-0b78f18255e5',
  identifier: 'window-frames-plastic-consumer'
}

export const windowFramesWoodConsumerNL = {
  id: '59ece369-0d89-411d-b224-aff0bc8984be',
  identifier: 'window-frames-wood-consumer'
}

export const windowFramesAluminiumConsumerNL = {
  id: '43a2fc35-f304-4684-ac26-fcf27224d283',
  identifier: 'window-frames-aluminium-consumer'
}

// NL - Corporate
export const windowFramesPlasticCorporateNL = {
  id: '9e217d59-b966-41d4-82bd-f3ebeff87f59',
  identifier: 'window-frames-plastic-corporate'
}

export const windowFramesWoodCorporateNL = {
  id: '0a212640-ba6e-4071-b27f-1a931944bd13',
  identifier: 'window-frames-wood-corporate'
}

export const windowFramesAluminiumCorporateNL = {
  id: 'ec75d649-17d2-4d61-b73f-a0b949de11ea',
  identifier: 'window-frames-aluminium-corporate'
}

// BE - Consumer
export const windowFramesPlasticConsumerBE = {
  id: '79ca9259-d8a3-4623-af18-f712e7164d30',
  identifier: 'window-frames-plastic-consumer'
}

export const windowFramesWoodConsumerBE = {
  id: '21858ab1-ffb1-4868-b185-93437002521c',
  identifier: 'window-frames-wood-consumer'
}

export const windowFramesAluminiumConsumerBE = {
  id: '3f162e39-497a-4b4a-a92c-ce750cb93084',
  identifier: 'window-frames-aluminium-consumer'
}

// BE - Corporate
export const windowFramesPlasticCorporateBE = {
  id: 'b67a5e41-b2c8-4f32-911d-735d9dce79d6',
  identifier: 'window-frames-plastic-corporate'
}

export const windowFramesWoodCorporateBE = {
  id: 'edb9dad3-b46c-4f19-8d2a-fbdfbe30f1c9',
  identifier: 'window-frames-wood-corporate'
}

export const windowFramesAluminiumCorporateBE = {
  id: '109da7da-d2cf-4c4e-9615-d8422eab8eab',
  identifier: 'window-frames-aluminium-corporate'
}
