import {
  hasConsentForServices,
  initializePluginWithConsent,
  ServiceIds
} from 'chimera/all/plugins/config/usercentrics.client'
import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Documentation: https://developers.hubspot.com/docs/api/events/tracking-code
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const { store } = (options = { ...options, ..._options })

  const requiredServices = [
    ServiceIds.HUBSPOT,
    ServiceIds.LINKEDIN_ADS,
    ServiceIds.LINKEDIN_INSIGHT_TAG,
    ServiceIds.LINKEDIN_PLUGIN,
    ServiceIds.FACEBOOK_SOCIAL_PLUGIN,
    ServiceIds.FACEBOOK_PIXEL,
    ServiceIds.GOOGLE_ADS
  ]

  const QUEUE_EVENT_KEY = 'hubspot'
  const enqueueEvent = async (eventName, fn) =>
    (await hasConsentForServices(requiredServices))
      ? fn()
      : store.dispatch('queue/enqueue', {
        key: QUEUE_EVENT_KEY,
        eventName,
        func: fn
      })

  bindEvents(Vue, { enqueueEvent })

  initializePluginWithConsent(() => {
    installScript()

    store.dispatch('queue/flush', QUEUE_EVENT_KEY)
  }, requiredServices)
}

/**
 *
 */
function installScript () {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = 'hs-script-loader'
  script.async = true
  script.defer = true
  script.src = '//js.hs-scripts.com/8674700.js'
  document.body.appendChild(script)
}
