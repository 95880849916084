// NL - Consumer
export const officeOfNotaryLegalEntitiesConsumerNL = {
  id: '9eca26e0-1320-419d-9800-6371d74b1571',
  identifier: 'office-of-notary-legal-entities-consumer'
}

export const officeOfNotaryHouseAndMortgageConsumerNL = {
  id: 'c2bdf96e-cb33-4f13-bead-4d0ad44c124a',
  identifier: 'office-of-notary-house-and-mortgage-consumer'
}

export const officeOfNotaryWillAndInheritanceConsumerNL = {
  id: '94f64bb9-c2af-4595-9aee-c0f527e413f7',
  identifier: 'office-of-notary-will-and-inheritance-consumer'
}

export const officeOfNotaryWeddingAndCohabitationConsumerNL = {
  id: '16f726c1-ee7d-4012-a6c7-23c04768ba34',
  identifier: 'office-of-notary-wedding-and-cohabitation-consumer'
}

export const officeOfNotaryDivorceConsumerNL = {
  id: 'e2ffec21-ae50-444b-b420-171364b53965',
  identifier: 'office-of-notary-divorce-consumer'
}

export const officeOfNotaryOtherServicesConsumerNL = {
  id: '3cdd81c1-6eea-417d-8ef6-3864a74076e7',
  identifier: 'office-of-notary-other-services-consumer'
}

// NL - Corporate
export const officeOfNotaryLegalEntitiesCorporateNL = {
  id: 'ccb9c78b-0e3b-48c1-bac5-32466b8e31f9',
  identifier: 'office-of-notary-legal-entities-corporate'
}

export const officeOfNotaryHouseAndMortgageCorporateNL = {
  id: 'db6012d8-f004-446c-ade5-662e360d8bfc',
  identifier: 'office-of-notary-house-and-mortgage-corporate'
}

export const officeOfNotaryWillAndInheritanceCorporateNL = {
  id: '0c34027e-e379-45d4-af2e-1ca0ad717554',
  identifier: 'office-of-notary-will-and-inheritance-corporate'
}

export const officeOfNotaryWeddingAndCohabitationCorporateNL = {
  id: '337a38d6-8c0d-4e9f-acf6-ff957bd74a21',
  identifier: 'office-of-notary-wedding-and-cohabitation-corporate'
}

export const officeOfNotaryDivorceCorporateNL = {
  id: '35a9be6e-412d-45d3-8cce-1b439ceaf665',
  identifier: 'office-of-notary-divorce-corporate'
}

export const officeOfNotaryOtherServicesCorporateNL = {
  id: 'a5aef8fc-c4a2-48a2-bfaf-fc63c07059d8',
  identifier: 'office-of-notary-other-services-corporate'
}
