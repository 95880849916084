<template>
  <header
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
    class="header"
  >
    <div class="header__container">
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  props: {
    backgroundImage: {
      type: String,
      default: 'images/hero.jpg'
    }
  }
}
</script>
