<template>
  <div
    class="t-card"
    :class="[colorVariant]"
    v-bind="{...$props, ...$attrs}"
  >
    <slot />
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TCard',

  mixins: [
    usesColor
  ]
}
</script>
