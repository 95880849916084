import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'
import { GoogleAnalyticsCustomDimension } from 'chimera/all/plugins/tracking/google/constants'
import { setDynamicEventScopeValue } from 'chimera/all/plugins/eventbus/scope'

/**
 * Sets the service_selection dimension which will be sent along with all future hits.
 *
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {object} event.service
 * @example {
 *   service_selection: 'real-estate-purchase-consumer'
}
 */
export function trackServiceSelection (Vue, { scope, service } = {}) {
  Vue.$gtag.event('service_selection', createScopedEvent(scope, {
    service_selection: service.identifier
  }))
}

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.service
 * @example {
 *   id: '14bda032-4132-426a-99ab-4a379986e9a5',
 *   identifier: 'real-estate-purchase-consumer'
 * }
 */
export function setServiceCustomDimension (Vue, { service } = {}) {
  setDynamicEventScopeValue(GoogleAnalyticsCustomDimension.SERVICE_SELECTION.name, service.identifier)
}
