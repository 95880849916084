module.exports = {
  global: {
    'contact-us': 'Contattaci',
    back: 'Indietro',
    continue: 'Continua',
    privacyPolicy: 'Politica sulla riservatezza',
    privacyPolicyLink: '/politica-sulla-riservatezza',
    cookiePolicy: 'Politica sui cookie',
    cookiePolicyLink: '/cookie',
    termsAndConditions: 'Termini e condizioni generali',
    termsAndConditionsLink: '/termini-e-condizioni-generali',
    imprint: '@todo',
    imprintLink: '@todo',
    companyLink: 'https://www.bambelo.com/en/',
    receiveUpToSixQuotes:
      'Ricevi fino a 6 preventivi da professionisti della tua zona e confrontali.',
    requiredFields: '@todo',
    other: 'Altro',
    yes: '@todo',
    no: '@todo'
  },

  validation: {
    required: 'Non stai dimenticando questo?',
    invalid: 'Sembra che qualcosa sia andato storto.',
    blacklisted: 'Sembra che qualcosa sia andato storto.',
    invalidLength: 'Il numero di caratteri non sembra essere corretto.'
  },

  field: {
    postal: {
      label: 'Codice postale',
      placeholder: 'Codice postale',
      validation: {
        invalid: 'Il tuo codice postale sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il tuo codice postale.',
        unknown: 'Il tuo codice postale non è a noi noto.',
        incorrectFormat:
          'Il tuo codice postale deve contenere 5 cifre (es. 12345).'
      }
    },

    comments: {
      label: 'Altre informazioni',
      placeholder: 'Altre informazioni',
      validation: {
        invalid: '@todo',
        required: 'Davvero non vuoi riferire nulla ai nostri esperti?'
      }
    },

    'address-city': {
      label: 'Città di residenza',
      placeholder: 'Città di residenza',
      validation: {
        invalid: 'La tua città di residenza sembra non essere corretta.',
        required: 'Hai dimenticato di inserire la tua città di residenza.'
      }
    },

    'address-country-name': {
      label: 'Nazione',
      placeholder: 'Nazione',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    'address-floor-level': {
      label: 'Pavimento',
      placeholder: 'Pavimento',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    'address-number': {
      label: 'Numero civico',
      placeholder: 'Numero civico',
      validation: {
        invalid: 'Il tuo numero civico sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il tuo numero civico.'
      }
    },

    'address-property-type': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    'address-street': {
      label: 'Indirizzo',
      placeholder: 'Indirizzo',
      validation: {
        invalid: 'Il nome della tua via sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il nome della tua via.'
      }
    },

    'company-name': {
      label: "Nome dell'azienda",
      placeholder: "Nome dell'azienda",
      validation: {
        invalid: 'Il nome della tua azienda risulta inesatto.',
        required: 'Hai dimenticato di inserire il nome della tua azienda.'
      }
    },

    'dormer-width': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    email: {
      label: 'E-mail',
      placeholder: 'E-mail',
      validation: {
        invalid: 'Il tuo indirizzo e-mail sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il tuo indirizzo e-mail.'
      }
    },

    name: {
      label: 'Nome',
      placeholder: 'Nome',
      validation: {
        invalid: 'Il tuo nome sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il tuo nome.'
      }
    },

    phone: {
      label: 'Telefono',
      placeholder: 'Telefono',
      validation: {
        invalid: 'Il tuo numero di telefono sembra non essere corretto.',
        required: 'Hai dimenticato di inserire il tuo numero di telefono.'
      }
    },

    message: {
      label: 'Messaggio',
      placeholder: 'Messaggio',
      validation: {
        invalid: '@todo',
        required: 'Hai dimenticato di inserire il tuo messaggio.'
      }
    },

    'contact-name': {
      label: 'Persona di riferimento',
      placeholder: 'Persona di riferimento',
      validation: {
        invalid: '@todo',
        required:
          'Hai dimenticato di inserire il nome della persona di riferimento.'
      }
    },

    'estimated-garden-area': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo'
      }
    },

    'move-preferred-date': {
      validation: {
        invalid: '@todo',
        required: '@todo'
      },
      flexibleDate: '@todo',
      specificDate: '@todo'
    },

    'period-of-construction': {
      options: {
        'before-1975': '@todo',
        'between-1975-and-1992': '@todo',
        'after-1992': '@todo'
      }
    },

    'type-of-heating': {
      options: {
        'floor-heating': '@todo',
        'floor-heating-and-radiators': '@todo',
        radiators: '@todo'
      }
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': '@todo',
        'air-water-heat-pump': '@todo',
        'ground-water-heat-pump': '@todo',
        'water-water-heat-pump': '@todo',
        'hybrid-heat-pump': '@todo',
        unknown: '@todo'
      }
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': '@todo',
        'cavity-wall-insulation': '@todo',
        'floor-insulation': '@todo',
        'double-glazing': '@todo',
        'no-insulation': '@todo'
      }
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': '@todo',
        'regular-radiator': '@todo',
        'low-temperature-radiator-description': '@todo',
        'regular-radiator-description': '@todo'
      }
    },

    'intends-to-replace-radiator': {
      options: {
        description: '@todo'
      }
    },

    'application-type': '@todo',
    'business-premises': '@todo',
    'agricultural-enterprise': '@todo',
    'large-business-requests': '@todo',
    'residential-building': '@todo',
    'type-of-assignment': '@todo',
    'house-battery': '@todo',
    'house-battery-and-solar-panels': '@todo',
    'estimated-annual-electricity-consumption': '@todo',
    'data-incompleted-error-message': '@todo'
  },

  'phone-verification': {
    title: 'Quasi fatto! Sei ad un passo dall’ottenere i tuoi preventivi.',
    instructions:
      'Per confermare che il tuo telefono sia corretto, ti invieremo un codice di verifica al',
    'send-code': 'Invia codice di verifica',
    'modify-number': 'Modifica numero'
  },

  'phone-validation': {
    title: 'Quasi fatto! Sei ad un passo dal ottenere i tuoi preventivi.',
    subtitle:
      'Vogliamo confermare che il tuo telefono sia corretto in modo che i professionisti possano contattarti.',
    instructions: 'Inserisci il codice che abbiamo inviato al telefono',
    'send-sms': 'Rinvia SMS',
    'edit-number': 'Modifica numero',
    validate: 'Convalida telefono',
    'verify-result': {
      'error-message': 'Codice non valido: riprova.'
    },
    'result-codes': {
      'sms-is-not-supported': 'Il numero di rete fissa non supporta gli SMS',
      'max-attempts-reached': 'Hai raggiunto il massimo di 5 tentativi.',
      'rate-limit-triggered':
        'Questo limite di velocità viene attivato quando il ciclo di vita della verifica (invio e verifica) non è completato.',
      'too-many-concurrent-requests':
        'Troppe richieste simultanee di numeri di telefono'
    }
  },

  privacyPolicy: {
    title: 'Informativa sulla privacy',
    headDescription: 'Informativa sulla privacy dei visitatori del sito Web',
    introduction:
      "{concept} attribuisce grande valore alla sua privacy. Per questo vogliamo essere aperti e trasparenti sul trattamento dei suoi dati personali. La presente informativa sulla privacy fornisce informazioni su come i suoi dati personali vengono trattati e protetti. La presente informativa sulla privacy si applica all'uso del sito web e dei servizi di {concept}a cui si accede tramite questo sito web.",

    section1Title: 'Chi tratta i suoi dati personali?',
    section1Body1:
      'La società olandese Bambelo B.V. elabora i dati personali attraverso uno dei nostri siti web ed è responsabile dei vostri dati personali ai sensi della legge sulla protezione dei dati. I nostri dati di contatto sono:',
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: '4811 VB',
    companyAddressCity: 'Breda Paesi Bassi',
    companyChamberOfCommerce: 'Numero di Camera di Commercio 20111350',
    section1Body2:
      "Se utilizza uno dei nostri siti web per richiedere un'offerta, trasmetteremo i suoi dati ai nostri partner affiliati che soddisfano i criteri da lei specificati. Tratteranno i suoi dati in conformità con la loro politica sulla privacy.  Sulla base di queste informazioni, queste aziende vi contatteranno via e-mail o per telefono per informarvi sui loro servizi e tariffe. Una singola richiesta tramite {concept} porterà quindi all'archiviazione dei dati presso {concept} stesso e presso le aziende collegate alla sua richiesta. Sulla base di leggi e regolamenti e di un accordo concluso tra Bambelo B.V. e le organizzazioni a cui Bambelo fornisce la sua richiesta, anche queste organizzazioni devono rispettare le disposizioni dell'accordo concluso con loro e il regolamento generale sulla protezione dei dati (GDPR). Troverete i dati di contatto di queste parti nelle offerte che riceverete da loro. Se un'azienda decide di non accettare l'offerta, sarà obbligata a distruggere i dati ricevuti.",

    section2Title: 'Dove conserviamo i suoi dati?',
    section2Body1:
      'I dati che raccogliamo saranno in linea di principio conservati all\'interno dello Spazio economico europeo ("SEE"). Se i dati vengono trasferiti e trattati da noi in un paese al di fuori del SEE, ciò avverrà in conformità alle leggi applicabili, normalmente sulla base di clausole contrattuali standard e, se necessario, di misure aggiuntive.',

    section3Title: 'Quali tipi di dati personali trattiamo e su quale base?',
    section3Body1: 'Trattiamo le seguenti categorie di dati personali:',
    section3List1Item1:
      'Dettagli di contatto, come nome, indirizzo, indirizzo e-mail e numero di telefono',
    section3List1Item2: 'Dati aggiuntivi rilevanti per la domanda, tra cui:',
    section3List1Item2Sub1: 'Categoria',
    section3List1Item2Sub2: 'Tipo di richiesta',
    section3List1Item2Sub3: 'Fascia di prezzo',
    section3List1Item2Sub4: 'Tipo di abitazione',
    section3List1Item2Sub5: 'Altre informazioni da voi specificate',
    section3Body2:
      'Elaboriamo i suddetti dati sulla base dei seguenti motivi legali:',
    section3List2Item1:
      'Se richiede preventivi tramite il nostro sito web: al fine di eseguire un contratto concluso con lei, o sulla base del suo consenso',
    section3List2Item2:
      'Se pone una domanda (tramite il nostro sito web): sulla base del suo permesso',
    section3List2Item3:
      'In altri casi: per la protezione degli interessi legittimi che abbiamo nel contesto delle nostre operazioni commerciali, come la gestione dei rapporti con i clienti, il miglioramento dei nostri servizi, il mantenimento del sito web sicuro e accessibile, e il mantenimento del contatto con i nostri clienti. Nel fare ciò, soppesiamo sempre questi interessi contro la sua privacy e trattiamo solo i dati necessari per raggiungere gli obiettivi definiti.',

    section4Title: 'Per quanto tempo conserviamo i suoi dati?',
    section4Body:
      "Conserveremo i suoi dati per un periodo massimo di 7 anni. Lo facciamo da un lato per rispettare il nostro periodo di conservazione legale e dall'altro per poter osservare le tendenze e migliorare i nostri servizi. Se lei indica che non desidera più utilizzare i nostri servizi o vuole che i dati vengano cancellati, valuteremo se questo periodo di conservazione può essere ridotto e quali dati possiamo cancellare.",

    section5Title: 'Chi ha accesso ai suoi dati?',
    section5Body:
      "I dati personali forniti dall'utente sono utilizzati per garantire il corretto funzionamento del servizio offerto da {concept}. {concept} fornisce solo i dati personali necessari ai partner affiliati al fine di fornirvi un'offerta adeguata in base alla vostra richiesta.",

    section6Title: 'Per cosa usiamo i suoi dati personali?',
    section6Body:
      "Utilizziamo i suoi dati principalmente allo scopo di richiedere offerte per il prodotto o il servizio che sta cercando. Li usiamo anche per valutare, sviluppare e migliorare i nostri servizi, prodotti e sistemi. Questo include l'analisi per rendere i nostri servizi più facili da usare, come cambiare l'interfaccia utente per semplificare il flusso di informazioni o evidenziare le caratteristiche che sono spesso utilizzate, e migliorare i sistemi IT per aumentare la sicurezza. A tal fine, non analizzeremo i suoi dati a livello individuale. Utilizziamo i dati anche per sviluppare nuovi servizi e migliorare la nostra gamma di prodotti. La fornitura (e l'elaborazione) di questi dati è una precondizione necessaria per l'esecuzione del contratto o la vostra richiesta di ricevere offerte. Se non fornisce i dati, non saremo in grado di servirla o rischiamo di servirla male.",

    section7Title: 'Quali sono i suoi diritti?',
    section7Right1Title: 'Diritto di accesso:',
    section7Right1:
      '{title} Lei ha il diritto di richiedere informazioni sui dati personali in nostro possesso in qualsiasi momento.',
    section7Right2Title: 'Diritto alla portabilità:',
    section7Right2:
      "{title} Se {concept} tratta i suoi dati personali in modo automatizzato sulla base del suo consenso o sulla base di un accordo, ha il diritto di ricevere una copia dei suoi dati in un formato strutturato, comunemente usato e leggibile da una macchina, trasferito a lei o a un'altra parte. Questo riguarda solo i dati personali che ci ha fornito.",
    section7Right3Title: 'Diritto all’oblio:',
    section7Right3:
      '{title} Ha il diritto di chiedere la rettifica dei suoi dati personali se sono errati, compreso il diritto di far completare i dati personali incompleti.',
    section7Right4Title: 'Diritto di dimenticare:',
    section7Right4:
      '{title} Ha il diritto di chiederci di rimuovere i dati personali trattati da {concept}.',
    section7Right5Title:
      'Diritto di limitare il trattamento e di opporsi al trattamento:',
    section7Right5:
      "{title} Può chiedere che il trattamento dei dati sia (temporaneamente) limitato, per esempio per controllare l'esattezza dei dati. Ha anche il diritto di opporsi al trattamento dei suoi dati personali. Se si oppone al trattamento per scopi di marketing diretto, i dati personali non saranno più trattati per questi scopi.",
    section7Right6Title: 'Diritto di ritirare il consenso:',
    section7Right6:
      '{title} Nella misura in cui trattiamo i dati sulla base del suo consenso, ha il diritto di ritirarlo in qualsiasi momento. Ciò non pregiudica la liceità del trattamento prima della revoca.',
    section7Body:
      'Per tutte le richieste di cui sopra, riceverà una risposta alla sua richiesta entro 1 mese. A seconda della complessità delle richieste e del numero di richieste, questo periodo può essere esteso di altri due mesi se necessario, nel qual caso la informeremo entro un mese dal ricevimento della sua richiesta.',

    section8Title: 'Come può esercitare i suoi diritti?',
    section8Body:
      "Prendiamo molto sul serio la protezione dei dati. Per questo motivo, abbiamo un team dedicato al servizio clienti che gestisce le sue domande sui suoi diritti di cui sopra e dove può presentare un reclamo. Può sempre raggiungere il nostro staff all'indirizzo {email}.",

    section9Title: 'Cooperazione nelle indagini fiscali e penali',
    section9Body:
      'Se necessario, potremmo essere obbligati per legge a condividere i suoi dati in relazione a indagini governative fiscali e penali. In questo caso, siamo costretti a condividere i suoi dati, ma ci opporremo a questo nei limiti delle possibilità della legge.',

    section10Title: 'Responsabile della protezione dei dati',
    section10Body:
      'Abbiamo nominato un responsabile della protezione dei dati (DPO) per garantire che trattiamo continuamente i vostri dati personali in modo aperto, accurato e legale. Può contattare il nostro FG a {email} con FG come soggetto.',

    section11Title:
      "Il diritto di presentare un reclamo a un'autorità di vigilanza",
    section11Body:
      "Se ritiene che {concept} non stia trattando correttamente i suoi dati personali, può contattarci. Ha anche il diritto di presentare un reclamo all'Autorità per i dati personali contro queste operazioni di trattamento. Può contattare l'Autorità per la protezione dei dati personali tramite questo {link}",
    section11BodyLinkText: 'link.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Aggiornamenti alla nostra dichiarazione sulla privacy',
    section12Body:
      "é possibile che la nostra dichiarazione sulla privacy debba essere aggiornata. L'ultima versione della dichiarazione sulla privacy è sempre disponibile sul nostro sito web. La informeremo di qualsiasi modifica sostanziale dell'Informativa sulla privacy, ad esempio relativa allo scopo dell'uso dei suoi dati personali, all'identità del Controllore o ai vostri diritti.",

    version: 'Versione 4.1 - 23 febbraio 2022'
  },

  cookiePolicy: {
    headTitle: 'Politica sui cookie',
    title: 'Cookie',
    whatIsCookies: {
      title: 'Cosa sono i cookie?',
      body: 'I cookie sono piccole informazioni che vengono memorizzate dal tuo browser sul tuo computer quando visiti il nostro sito web e che possono essere recuperate nelle visite successive. Su questo sito utilizziamo i cookie.'
    },
    whatIsCookieFunction: {
      title: 'Qual è la funzione di questi cookie?',
      firstParagraph: 'Utilizziamo i cookie per assicurarci che tu non riceva o inserisca ripetutamente le stesse informazioni quando visiti il nostro sito web. Esistono diversi tipi di cookie. In allegato troverai una panoramica di tutti i cookie utilizzati, chi li inserisce, il nome del cookie, lo scopo del suo posizionamento, il periodo di memorizzazione del cookie e le conseguenze relative alla mancata accettazione.',
      secondParagraph: 'Puoi disabilitare la memorizzazione dei cookie sul tuo computer tramite il browser. Puoi anche essere avvisato quando ricevi un cookie. Le istruzioni su come regolare le impostazioni del browser possono essere trovate sotto \'aiuto\' nella barra degli strumenti della maggior parte dei browser. Sebbene tu come visitatore possa comunque visitare il sito, è possibile che alcuni servizi o elementi del sito non funzionino in modo ottimale qualora i cookie vengano rifiutati. Disattivare i cookie limiterà l\'utilizzo del nostro sito e dei nostri servizi.',
      thirdParagraph: 'Alcuni cookie di tracciamento vengono inseriti da terze parti che, tra l\'altro, mostrano annunci pubblicitari tramite il nostro sito web. È possibile eliminare questi cookie a livello centrale tramite {link} in modo che non vengano reintegrati su un sito web di terzi.',
      url: 'https://www.youronlinechoices.com/it/le-tue-scelte'
    },
    whoIAm: {
      title: 'I cookie sanno chi sono?',
      body: 'No. Il cookie non conosce il tuo nome, indirizzo, età e altre informazioni personali. Ricordano solo le tue preferenze e i tuoi interessi in base al tuo comportamento di navigazione.'
    },
    listTitle: 'Elenco dei cookie',
    adjustPreferences: 'Regola le preferenze dei cookie',
    version: 'Versione'
  },

  termsAndConditions: {
    title: 'Termini e condizioni generali',
    headDescription: 'Termini e condizioni generali'
  }
}
