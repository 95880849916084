import { EventBus } from 'chimera/all/plugins/eventbus'

export const getDefaultState = () => {
  return {
    landingPage: {
      url: '/',
      identifier: 'index',
      layoutPath: ''
    }
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * Get landing page configuration values.
   *
   * @param {object} state
   * @returns {Function}
   */
  getLandingPage: (state) => {
    return (key = undefined) => {
      if (undefined === key) {
        return state.landingPage
      }

      // Only support getting actual existing keys.
      if (!(key in state.landingPage)) {
        EventBus.emitErrorAppErrorEvent(
          new Error(`configuration/getters.getLandingPage: ${key} is an invalid key`), { key }
        )
        return undefined
      }

      return state.landingPage[key]
    }
  }
}

export const mutations = {
  /**
   * @param {object} state
   * @param {object} configuration
   */
  merge (state, configuration) {
    Object.assign(state, configuration)
  }
}

export const actions = {
  /**
   * @param {object} context
   * @param {Function} context.commit
   * @param {object} landingPage
   * @param {string} landingPage.url
   * @param {string} landingPage.identifier
   * @param {string} landingPage.layoutPath
   */
  setLandingPage ({ commit }, { url, identifier, layoutPath }) {
    // When one of the required parameters is missing, we emit an error event and return.
    if (undefined === url || undefined === identifier || undefined === layoutPath) {
      EventBus.emitErrorAppErrorEvent(
        new Error('configuration/actions.setLandingPage: url, identifier and layoutPath are required'), { url, identifier, layoutPath }
      )
      return
    }
    commit('merge', {
      landingPage: {
        url,
        identifier,
        layoutPath
      }
    })
  },

  /**
   * Resets the landing page to the default state.
   *
   @param {object} context
   @param {Function} context.commit
   */
  resetLandingPage ({ commit }) {
    commit('merge', {
      landingPage: getDefaultState().landingPage
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
