import { trackPageView, trackPurchase, trackServiceSelection } from 'chimera/all/plugins/tracking/meta/events'

/**
 * Our event handling for Meta Pixels.
 *
 * @param {object} Vue
 * @param {object} options
 * @param {Function} options.enqueueEvent
 */
export function bindEvents (Vue, { enqueueEvent }) {
  // Track page view events.
  Vue.$eventBus.onPageViewEvent((event) => {
    enqueueEvent('onPageViewEvent', () => trackPageView(event))
  })

  // On service selection
  Vue.$eventBus.onServiceSelectionEvent((event) => {
    enqueueEvent('onServiceSelectionEvent', () => trackServiceSelection(event))
  })

  // On transaction event we track conversion goal.
  Vue.$eventBus.onTransactionEvent((event) => {
    enqueueEvent('onTransactionEvent', () => trackPurchase(event))
  })
}
