<template>
  <div
    :id="id"
    class="t-selectable"
    :class="[
      {
        't-selectable--selected' : isSelected,
        't-selectable--description' : options && options.description,
      },
      colorVariant
    ]"
    @click="onClick()"
  >
    <div class="t-selectable__wrapper">
      <div class="t-selectable__column">
        <input
          :id="id + '-selectable'"
          :role="role"
          :type="type"
          class="t-selectable__input"
          :value="value"
        >

        <div class="t-selectable__icon">
          <span class="t-selectable__icon-wrapper">
            <transition name="fade">
              <div :key="isSelected ? 'selected' : 'unselected'" class="flex absolute inset-0">
                <t-icon :icon="isSelected ? selectedIcon : unselectedIcon" />
              </div>
            </transition>
          </span>
        </div>
      </div>

      <div class="t-selectable__column w-full">
        <label :for="id + '-selectable'" class="t-selectable__label" @click.prevent>
          <div v-if="label" class="t-selectable__text-container">
            <span class="t-selectable__text">{{ label }}</span>
          </div>

          <div v-if="options && options.subtext" class="t-selectable__subtext-container">
            <span class="t-selectable__subtext">{{ options.subtext }}</span>
          </div>

          <div v-if="options && options.tooltip" class="t-selectable__tooltip-container">
            <Tooltip class="t-selectable__tooltip" :content="options.tooltip" />
          </div>
        </label>

        <div v-if="options && options.description" class="t-selectable__description-container">
          <p class="t-selectable__description">
            {{ options.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractTSelectable from 'chimera/all/components/elements/AbstractTSelectable'

export default {
  name: 'TSelectable',

  extends: AbstractTSelectable
}
</script>
