<template>
  <DropdownNavigationBar>
    <template #default>
      <a class="flex text-gray-600" :href="$t('phoneLink')">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-6 mr-2" viewBox="0 0 14.94 22.59">
          <g>
            <path
              d="M10.76,5l2.77,5.32-.89.46c-1.13.59-.69,3,.54,5.36s3,4.07,4.08,3.51l.89-.46,2.77,5.32-1.74.92c-1.5.79-5.61-2.11-8.63-7.9S7.48,6.71,9,5.92L10.76,5m-.46-.86L8.52,5c-2.4,1.25-1.89,7.18,1.11,13s7.58,9.58,10,8.33l1.78-.92a1,1,0,0,0,.42-1.35l-2.77-5.32a1,1,0,0,0-1.35-.43h0l-.89.46c-.29,0-1.56-.82-2.73-3.08-1.28-2.44-1.17-3.9-1-4l.88-.46a1,1,0,0,0,.43-1.35h0L11.64,4.54A1,1,0,0,0,10.3,4.14Z"
              transform="translate(-7 -4.02)"
              fill="currentColor"
            />
          </g>
        </svg>
        {{ $t('phoneReadable') }}
      </a>
    </template>

    <template #body>
      <ul class="navbar-dropdown__menu-items">
        <li class="navbar-dropdown__menu-item">
          <a class="navbar-dropdown__menu-link flex" :href="$t('phoneLink')">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-6 mr-2" viewBox="0 0 14.94 22.59">
              <g>
                <path
                  d="M10.76,5l2.77,5.32-.89.46c-1.13.59-.69,3,.54,5.36s3,4.07,4.08,3.51l.89-.46,2.77,5.32-1.74.92c-1.5.79-5.61-2.11-8.63-7.9S7.48,6.71,9,5.92L10.76,5m-.46-.86L8.52,5c-2.4,1.25-1.89,7.18,1.11,13s7.58,9.58,10,8.33l1.78-.92a1,1,0,0,0,.42-1.35l-2.77-5.32a1,1,0,0,0-1.35-.43h0l-.89.46c-.29,0-1.56-.82-2.73-3.08-1.28-2.44-1.17-3.9-1-4l.88-.46a1,1,0,0,0,.43-1.35h0L11.64,4.54A1,1,0,0,0,10.3,4.14Z"
                  transform="translate(-7 -4.02)"
                  fill="currentColor"
                />
              </g>
            </svg>
            {{ $t('phoneReadable') }}
          </a>
        </li>
      </ul>
    </template>
  </DropdownNavigationBar>
</template>

<script>
import DropdownNavigationBar from './DropdownNavigationBar'

export default {
  name: 'ContactProDropdownNavigationBar',

  components: {
    DropdownNavigationBar
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "phoneLink": "tel:+31765246243",
    "phoneReadable": "(0)76 5 246 243"
  },
  "nl-BE": {
    "phoneLink": "tel:+3233930991",
    "phoneReadable": "(0)3 39 30 991"
  },
  "es-ES": {
    "phoneLink": "tel:+34930464785",
    "phoneReadable": "930 46 47 85"
  },
  "de-DE": {
    "phoneLink": "tel:+31765246243",
    "phoneReadable": "(0)76 5 246 243"
  },
  "fr-BE": {
    "phoneLink": "tel:+32466907668",
    "phoneReadable": "(0)4 66 90 76 68"
  },
  "fr-FR": {
    "phoneLink": "tel:+33805988008",
    "phoneReadable": "(0)8 05 98 80 08"
  },
  "it-IT": {
    "phoneLink": "tel:+39800694295",
    "phoneReadable": "(0)800 694 295"
  }
}
</i18n>
