import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {number} event.step
 * @example {
 *   step: 1+
 *   scope: scopeObject
 * }
 */
export function trackBeginCheckoutStep (Vue, { scope, step } = {}) {
  // Assert that our event is received correctly.
  if (!Number.isInteger(step)) {
    throw new TypeError(`Received beginCheckoutStep event with step ${step}`)
  }

  const eventName = 'begin_checkout'

  Vue.$gtag.event(eventName, createScopedEvent(scope, {
    begin_checkout: step
  }))
}

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.id
 * @param {number} event.revenueMade
 * @param {object} event.service
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce (global)
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce (details)
 */
export function trackPurchase (Vue, { scope, id, revenueMade, service } = {}) {
  if (typeof id !== 'string' || typeof revenueMade !== 'number' || !service) {
    throw new TypeError(`Received purchase event with id ${id}, revenue ${revenueMade}, service: ${service}`)
  }

  Vue.$gtag.event('purchase', createScopedEvent(scope, {
    transaction_id: id,
    affiliation: process.env.CONCEPT,
    value: revenueMade,
    currency: 'EUR',
    items: [{
      id,
      name: 'Offerteaanvraag',
      category: service.identifier,
      price: revenueMade,
      quantity: 1
    }]
  }))
}
