import { parsePhoneNumberFromString } from 'libphonenumber-js/core'

/**
 * Generated using --countries NL,BE,FR,IT,ES,DE --types mobile,fixed_line
 * https://gitlab.com/catamphetamine/libphonenumber-metadata-generator
 */
import metadata from './metadata.custom.json'

/**
 * @param {string} phone
 * @param {string} country
 * @returns {object}
 */
export default function (phone, country) {
  const parsedPhoneNumber = parsePhoneNumberFromString(phone, country.toUpperCase(), metadata)

  return {
    number: parsedPhoneNumber?.number,
    nationalNumber: parsedPhoneNumber?.nationalNumber,
    country: parsedPhoneNumber?.country,
    type: parsedPhoneNumber?.getType(),
    isValid: parsedPhoneNumber?.isValid(),
    isPossible: parsedPhoneNumber?.isPossible()
  }
}
