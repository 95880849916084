import {
  hasConsentForServices,
  initializePluginWithConsent,
  ServiceIds
} from 'chimera/all/plugins/config/usercentrics.client'
import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Documentation:
 * http://bingadsuet.azurewebsites.net/SPA_Index.html
 * https://help.ads.microsoft.com/#apex/ads/en/56681/2-500
 * https://help.ads.microsoft.com/apex/index/3/en/56687
 *
 * Chrome Extension: https://chrome.google.com/webstore/detail/uet-tag-helper-by-bing-ad/naijndjklgmffmpembnkfbcjbognokbf/related?hl=en
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const { tagId, store } = (options = {
    ...options,
    ..._options
  })

  loadUET(tagId)

  window.uetq = window.uetq || []
  window.uetq.push('consent', 'default', {
    ad_storage: 'denied'
  })

  const requiredServices = [ServiceIds.MICROSOFT_ADVERTISING_REMARKETING]

  const QUEUE_EVENT_KEY = 'microsoft_ads'
  const enqueueEvent = async (eventName, fn) =>
    (await hasConsentForServices(requiredServices))
      ? fn()
      : store.dispatch('queue/enqueue', {
        key: QUEUE_EVENT_KEY,
        eventName,
        func: fn
      })

  bindEvents(Vue, { enqueueEvent })

  initializePluginWithConsent(() => {
    window.uetq.push('consent', 'update', {
      ad_storage: 'granted'
    })

    store.dispatch('queue/flush', QUEUE_EVENT_KEY)
  }, requiredServices)
}

/**
 * This loads the UET tag for us.
 *
 * Note that this snippet has been manually modified and includes a disableAutoPageView: true. This change was
 * found in the original SPA documentation of Microsoft Ads:
 * http://bingadsuet.azurewebsites.net/SPA_DirectAllPagesNoAuto.html
 *
 * @param {string} tagId
 */
export function loadUET (tagId) {
  /* eslint-disable */
  (function (w, d, t, r, u) {
    let f, n, i;
    (w[u] = w[u] || []),
      (f = function () {
        const o = {
          ti: tagId,
          disableAutoPageView: true,
        };
        (o.q = w[u]), (w[u] = new UET(o)), w[u].push("pageLoad");
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.async = 1),
      (n.onload = n.onreadystatechange =
        function () {
          const s = this.readyState;
          (s && s !== "loaded" && s !== "complete") ||
            (f(), (n.onload = n.onreadystatechange = null));
        }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i);
  })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
  /* eslint-enable */
}
