import { bus } from 'chimera/all/plugins/eventbus/bus'

/**
 * Binds a callback to a specific event happening.
 *
 * @param {string} event
 * @param {Function} callback
 */
export function bindOn (event, callback) {
  bus.$on(event, callback)
}

/**
 * Binds a one-time callback to a specific event happening.
 *
 * @param {string} event
 * @param {Function} callback
 */
export function bindOnceOn (event, callback) {
  bus.$once(event, callback)
}
