<template>
  <div class="t-dialog__container">
    <Transition :name="transition">
      <div
        v-show="show"
        class="t-dialog"
        :class="[
          {
            't-dialog--closed': !show,
            't-dialog--open': show,
            't-dialog--fullscreen-mobile': fullscreenMobile,
            't-dialog--fullscreen-desktop': fullscreenDesktop,
            't-dialog--scrollable': scrollable
          },
          colorVariant
        ]"
        v-bind="{...$props, ...$attrs}"
        v-on="$listeners"
      >
        <t-dialog-card :close-button-text="closeButtonText" :color="color" @close="onClose">
          <slot />
        </t-dialog-card>
      </div>
    </Transition>
  </div>
</template>

<script>
import TDialogCard from 'chimera/all/components/elements/TDialogCard'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TDialog',

  components: {
    TDialogCard
  },

  mixins: [
    usesColor
  ],

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      default: 'dialog'
    },

    closeButtonText: {
      type: String,
      default: 'Sluiten'
    },

    value: {
      type: Boolean,
      default: false
    },

    fullscreenDesktop: {
      type: Boolean,
      default: false
    },

    fullscreenMobile: {
      type: Boolean,
      default: true
    },

    /**
     * Content is scrollable, instead of complete dialog
     */
    scrollable: {
      type: Boolean,
      default: true
    },

    transition: {
      type: String,
      default: 'slide-top'
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      show: this.value
    }
  },

  /**
   * Add classes to body or html
   *
   * @returns {{htmlAttrs}}
   */
  head () {
    return {
      htmlAttrs: {
        class: ((this.fullscreenMobile || this.fullscreenDesktop) && this.show) ? 'overflow-y-auto' : ''
      }
    }
  },

  watch: {
    /**
     * @param {string} value
     */
    value (value) {
      this.show = value
    },

    /**
     * Emits tracking input and state on dialog show state
     *
     * @param {any} value
     */
    show (value) {
      const showState = value ? 'open' : 'close'

      this.$eventBus.emitDialogEvent(
        this.id,
        showState
      )

      this.$emit('input', value)
      this.$emit(showState, value)
    }
  },

  methods: {
    /**
     * On close button clicked
     */
    onClose () {
      this.show = false
    }
  }
}
</script>
