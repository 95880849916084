<template>
  <section class="section main-cta">
    <div class="header-image" />

    <div class="content">
      <h1 class="content__title__main section-title">
        <slot name="title" />
      </h1>

      <h2 class="content__title__sub">
        <slot name="subTitle" />
      </h2>

      <div class="content__cta">
        <div class="content__cta__container">
          <TCard class="header__card shadow-box">
            <div class="card__form">
              <slot name="form" />
            </div>
          </TCard>
        </div>
      </div>
      <div class="content__more-content">
        <InlineSvg class="arrow-down" src="/images/landingPage/icon-arrow-down.svg" />
      </div>
    </div>
  </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'HeaderSection',
  components: {
    InlineSvg
  }
}
</script>

<style lang="scss" scoped>
  .section.main-cta {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .header-image {
      @apply
      w-full
      min-h-[232px]
      md:min-h-[356px]
      mb-6
      xl:mb-20
      bg-cover
      bg-center
      bg-repeat
      bg-[url('/images/landingPage/hero-mobile.jpg')]
      md:bg-[url('/images/landingPage/hero-desktop.jpg')]
      xl:bg-[url('/images/landingPage/hero-desktop-xl.jpg')];
    }

    .content {
      @apply
      mb-6
      xl:mb-28
      xl-down:space-y-8;

      grid-template-areas:
        "title"
        "cta"
        "sub-title"
        "more";

      @screen xl {
        grid-template-areas:
        ". title     title     title     title     title     cta  cta cta cta cta ."
        ". sub-title sub-title sub-title sub-title sub-title cta  cta cta cta cta ."
        ". .         .         .         .         more      more .   .   .   .   .";
      }
    }

    .content__title {
      &__main {
        grid-area: title;
        @apply xl:relative xl:-top-2 xl-down:text-[2.5rem];
      }

      &__sub {
        grid-area: sub-title;
        @apply xl:absolute xl:top-8 text-base xl:text-lg xl-down:text-center;
      }
    }

    .content__cta {
      grid-area: cta;
      @apply xl:relative xl:top-4 xl:col-span-5;

      &__container {
        @apply flex justify-center xl-down:w-full;
      }

      .header__card {
        @apply xl:w-[344px]
      }
    }

    .content__more-content {
      @apply flex flex-col items-center w-full;
      grid-area: more;

      .arrow-down {
        @apply w-11 h-[2.375rem] xl:h-[3rem] p-2.5 xl-down:pt-0 animate-[bounce_1.8s_infinite];
        animation-timing-function: cubic-bezier(.41,0,.59,.97);

        @keyframes bounce {
          0%, 100% {
            transform: translateY(-16px);
          }
          50% {
            transform: translateY(6px);
          }
        }
      }
    }
  }
</style>
