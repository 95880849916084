import Vue from 'vue'
import Sentry from 'chimera/all/plugins/sentry'
import { severityFatal } from 'chimera/all/plugins/eventbus/events'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env, redirect }) => {
  Vue.use(Sentry, {
    concept: env.concept,
    environment: env.environment,
    release: env.version,
    tracingOrigins: [
      env.apiUrl.replace('https://', ''),
      env.postalApiUrl.replace('https://', '')
    ]
  })

  // Redirect to the error page on fatal app error
  EventBus.onAppErrorEvent(({ severity }) => {
    if (severity === severityFatal) {
      redirect('/error')
    }
  })
}
