import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {object} event.error
 * @example {
 *   error: {
 *     message: test is not defined,
 *     stack: test is not defined at HTMLButtonElement.onclick (index):571)
 *   }
 *   scope: scopeObject
 * }
 */
export function trackAppError (Vue, { scope, error } = {}) {
  Vue.$gtag.event(
    'app_error',
    createScopedEvent(scope, {
      message: error.message,
      stack: error.stack
    })
  )
}

/**
 * When a field is validated and invalid we track the error so we can analyse
 * the amount of invalid inputs and what errors occur most.
 *
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.scope
 * @param {string} event.field
 * @param {string[]} event.errors
 * @example {
 *   field: 'postal',
 *   errors: [
 *     'invalid_regex',
 *     'invalid_postal'
 *   ],
 *   scope: scopeObject
 * }
 */
export function trackUserError (Vue, { scope, field, errors } = {}) {
  // Assert that our event is received correctly.
  if (field === undefined || !Array.isArray(errors)) {
    throw new TypeError(
      `Received form error event with field ${field} and errors type: ${typeof errors}`
    )
  }

  Vue.$gtag.event(
    'user_error',
    createScopedEvent(scope, {
      field,
      message: errors[0]
    })
  )
}
