/**
 * PD-6492
 * @param {object} Vue
 * @param {object} event
 * @param {string} event.tokenLength
 * @param {string} event.transactionId
 */
export function trackRecaptchaTokenLength (Vue, { tokenLength, transactionId } = {}) {
  Vue.$gtag.event('recaptcha_token', {
    recaptcha_token_length: tokenLength,
    recaptcha_transaction_id: transactionId
  })
}
