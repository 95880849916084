// NL
export const boilerReplacementConsumerNL = {
  id: 'dc574e4f-9b0a-401f-b382-dfe3b82fa027',
  identifier: 'boiler-replacement-consumer'
}

export const boilerInstallationConsumerNL = {
  id: '5a8d155b-b7b6-4526-86f8-19eef3b098f2',
  identifier: 'boiler-installation-consumer'
}

export const boilerServiceConsumerNL = {
  id: '76edec36-2625-4e07-89cb-ecf47088834d',
  identifier: 'boiler-service-consumer'
}

export const boilerRepairConsumerNL = {
  id: '53170d30-b230-475b-880e-62013f96faf6',
  identifier: 'boiler-repair-consumer'
}
export const boilerReplacementCorporateNL = {
  id: 'eaffbf25-1fcd-4718-901b-d86ae9fb9e4c',
  identifier: 'boiler-replacement-corporate'
}

export const boilerInstallationCorporateNL = {
  id: 'bab4a11d-567b-4241-882d-d466590fd14c',
  identifier: 'boiler-installation-corporate'
}

export const boilerServiceCorporateNL = {
  id: '198f97ec-c5fc-4bd6-af04-c810f5b031ac',
  identifier: 'boiler-service-corporate'
}

export const boilerRepairCorporateNL = {
  id: '084a5416-9a81-4d93-b3d3-94b5060abd36',
  identifier: 'boiler-repair-corporate'
}
