<template>
  <div class="t-rating" :class="[colorVariant]">
    <template v-for="index in fullStars">
      <t-icon
        :key="'full-star-' + index"
        :color="color"
        :icon="mdiStar"
        class="t-icon__star t-icon__star--full"
      />
    </template>

    <template v-for="index in halfStars">
      <t-icon
        :key="'half-full-star-' + index"
        :color="color"
        :icon="mdiStarHalfFull"
        class="t-icon__star t-icon__star--half"
      />
    </template>

    <template v-for="index in emptyStars">
      <t-icon
        :key="'empty-star-' + index"
        :color="color"
        :icon="mdiStarOutline"
        class="t-icon__star t-icon__star--empty"
      />
    </template>
  </div>
</template>

<script>
import { mdiStar, mdiStarHalfFull, mdiStarOutline } from '@mdi/js'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TRating',

  mixins: [
    usesColor
  ],

  props: {
    size: {
      type: [Number, String],
      default: 5
    },

    value: {
      type: [String, Number],
      default: 0
    },

    color: {
      type: String,
      default: 'warning'
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      mdiStar,
      mdiStarHalfFull,
      mdiStarOutline
    }
  },

  computed: {
    /**
     * @returns {number}
     */
    totalStars () {
      return Math.max(0, this.size)
    },

    /**
     * @returns {number}
     */
    fullStars () {
      const amount = Math.floor(this.value)
      return Math.min(Math.max(amount, 0), this.totalStars)
    },

    /**
     * @returns {number}
     */
    halfStars () {
      const isInteger = this.value % 1 === 0
      const noHalves = isInteger || this.totalStars === this.fullStars
      const noStarsToShow = this.totalStars <= 0

      return noStarsToShow || noHalves ? 0 : 1
    },

    /**
     * @returns {number}
     */
    emptyStars () {
      const amount = this.totalStars - Math.ceil(this.value)
      return Math.min(Math.max(amount, 0), this.totalStars)
    }
  }
}
</script>
