<template>
  <div
    class="t-progress-bar"
    :class="[colorVariant]"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="value"
  >
    <div class="t-progress-bar__progress" :style="{'width': width}" />
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TProgressBar',

  mixins: [
    usesColor
  ],

  props: {
    value: {
      type: Number,
      default: undefined
    },

    color: {
      type: String,
      default: 'warning'
    }
  },

  computed: {
    /**
     * @returns {number}
     */
    progress () {
      const value = this.value || this.$store.getters['progressbar/progress'] || 0
      return Math.min(Math.max(0, value), 100)
    },

    /**
     * @returns {string}
     */
    width () {
      return `${this.progress}%`
    }
  }
}
</script>
