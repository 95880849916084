/**
 * Contains the dynamic scope values to be used by the event bus.
 *
 * @type {{}}
 */
let dynamicEventScope = {}

/**
 * @returns {object}
 */
export function getEventScope () {
  const defaultScope = {
    title: document.title,
    href: window.location.href,
    protocol: window.location.protocol,
    host: window.location.host,
    hostname: window.location.hostname,
    port: window.location.port,
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash
  }

  return Object.assign({}, defaultScope, dynamicEventScope)
}

/**
 * @param {string} property
 * @param {string} value
 */
export function setDynamicEventScopeValue (property, value) {
  dynamicEventScope[property] = value
}

/**
 * @param {string} property
 * @returns {string}
 */
export function getDynamicEventScopeValue (property) {
  return dynamicEventScope[property]
}

/**
 */
export function resetDynamicEventScope () {
  dynamicEventScope = {}
}
