/**
 * Mixin to be used color support.
 *
 * @returns {object}
 */
export default {
  props: {
    color: {
      type: String,
      default: ''
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    colorVariant () {
      if (!this.color) {
        return ''
      }

      return `theme-color--${this.color}`
    }
  }
}
