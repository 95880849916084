import { EventBus } from 'chimera/all/plugins/eventbus'
import {
  hasConsentForServices,
  initializePluginWithConsent,
  ServiceIds
} from 'chimera/all/plugins/config/usercentrics.client'
import { bindEvents } from './bind'

/**
 * Options object which is merged with passed options and
 * can be retrieved by importing getOptions function.
 *
 * @type {object}
 */
let options = {}

/**
 * @returns {object}
 */
export const getOptions = () => options

/**
 * Chrome Extension: https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc
 *
 * @param {object} Vue
 * @param {object} _options
 */
export default (Vue, _options) => {
  const { pixels, store } = (options = {
    ...options,
    ..._options
  })

  if (!pixels || !Array.isArray(pixels)) {
    EventBus.emitWarningAppErrorEvent(
      new Error('No configured pixels found. Skipping initialisation.'),
      { pixels }
    )
    return
  }

  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) {
      return;
    }
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) {
      f._fbq = n;
    }
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  /* eslint-enable */

  window.fbq('consent', 'revoke')

  // Initialise pixels
  pixels.forEach(pixel => window.fbq('init', pixel))

  const requiredServices = [ServiceIds.FACEBOOK_PIXEL]

  const QUEUE_EVENT_KEY = 'facebook_ads'
  const enqueueEvent = async (eventName, fn) =>
    (await hasConsentForServices(requiredServices))
      ? fn()
      : store.dispatch('queue/enqueue', {
        key: QUEUE_EVENT_KEY,
        eventName,
        func: fn
      })

  bindEvents(Vue, { enqueueEvent })

  initializePluginWithConsent(() => {
    window.fbq('consent', 'grant')

    store.dispatch('queue/flush', QUEUE_EVENT_KEY)
  }, requiredServices)
}
