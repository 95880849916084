import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * When a dialog is opened or closed.
 *
 * @type {string}
 */
export const dialogEvent = 'eb_dialog'

/**
 * @param {string} who -   The dialog.(id) that has been clicked on.
 * @param {string} what -  What happened: open/close
 */
export function emitDialogEvent (who, what) {
  emit(dialogEvent, {
    who,
    what,
    scope: getEventScope()
  })
}

/**
 * @param {Function} callback
 */
export function onDialogEvent (callback) {
  bindOn(dialogEvent, callback)
}
