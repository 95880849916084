import { EventBus } from 'chimera/all/plugins/eventbus/index'

/**
 * This plugin is responsible for encapsulating native error handling and vue specific error
 * handling. Any errors will be passed around on the EventBus for further processing.
 *
 * @param {object} Vue
 */
export function bindVueErrorEvents (Vue) {
  // Handle vue specific errors.
  const next = Vue.config.errorHandler
  Vue.config.errorHandler = function (err, vm, info) {
    EventBus.emitJsErrorEvent(err)
    if (typeof next === 'function') {
      next(err, vm, info)
    }
  }
}

/**
 * Binds our handleVanillaError to the window.onError function (client only).
 */
export function bindJavascriptErrorEvents () {
  // Handle any vanilla javascript errors on the client.
  if (process.client) {
    window.onerror = handleVanillaError
  }
}

/**
 * Function which overwrites the window.onerror method and emits an error object onto
 * a JSErrorEvent on EventBus. Has a fallback to reconstruct a compatible error object
 * when it's undefined.
 *
 * @param {string} message
 * @param {string} source
 * @param {number} line
 * @param {number} column
 * @param {Error|undefined} error
 * @returns {Error}
 */
export function handleVanillaError (message, source = 'Unknown Script', line = 0, column = 0, error = undefined) {
  // Create a fall-back error object when argument is not present.
  if (error === undefined || error === null) {
    error = new Error(`${message} at ${source} code:${line}:${column}`)
  }

  EventBus.emitJsErrorEvent(error)

  return error
}
