import Vue from 'vue'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Tracking Directive.
 *
 * @param {object} context
 * @param {object} context.app
 * @param {object} context.route
 */
export default ({ app, route }) => {
  Vue.directive('trackClick', {
    /**
     * @param {HTMLElement} element
     * @param {object} binding
     */
    bind (element, binding) {
      // Due to a race condition between handling the click event on f.e. a submit button and the
      // navigation it's possible that the event is handled after the navigation has been completed. In
      // this case, the event would create a scope with the new location data - making the event invalid.
      // To address this issue we get the scope on bind, and send that along with the event.
      const scope = getEventScope()

      const {
        who = element.id
      } = binding.value || {}

      element.addEventListener('click', () => {
        EventBus.emitClickEventInScope(scope, who, 'element')
      })
    }
  })
}
