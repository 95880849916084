import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { emit } from './emit'

export const severityWarning = 'warning'
export const severityError = 'error'
export const severityFatal = 'fatal'

/**
 * A deliberately thrown error event to log and alert our
 * development team of bugs.
 *
 * @type {string}
 */
export const appErrorEvent = 'eb_errorApp'

/**
 * Emit AppErrorEvent with severity error.
 *
 * @param {Error} error
 * @param {object} context - key/value object.
 */
export function emitErrorAppErrorEvent (error, context) {
  emitAppErrorEvent(error, severityError, context)
}

/**
 * Emit AppErrorEvent with severity fatal.
 *
 * @param {Error} error
 * @param {object} context - key/value object.
 */
export function emitFatalAppErrorEvent (error, context) {
  emitAppErrorEvent(error, severityFatal, context)
}

/**
 * Emit AppErrorEvent with severity warning.
 *
 * @param {Error} error
 * @param {object} context - key/value object.
 */
export function emitWarningAppErrorEvent (error, context) {
  emitAppErrorEvent(error, severityWarning, context)
}

/**
 * Helper method which abstracts away the AppErrorEvent emit.
 *
 * @param {Error} error
 * @param {string} severity
 * @param {object} context
 */
function emitAppErrorEvent (error, severity, context) {
  emit(appErrorEvent, {
    error,
    severity,
    context,
    scope: getEventScope()
  })
}

/**
 * Binds a function to listen on the AppErrorEvent.
 *
 * @param {Function} callback
 */
export function onAppErrorEvent (callback) {
  bindOn(appErrorEvent, callback)
}
