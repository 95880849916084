import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'

/**
 * Triggers when lead is submitted and passing the recaptcha token length that was assigned - PD-6492.
 *
 * @type {string}
 */
export const recaptchaTokenLengthEvent = 'eb_recaptchaTokenLengthEvent'

/**
 * @param {string} tokenLength
 * @param {string} transactionId
 */
export function emitRecaptchaTokenLengthEvent (tokenLength, transactionId) {
  emit(recaptchaTokenLengthEvent, {
    tokenLength,
    transactionId,
    scope: getEventScope()
  })
}

/**
 * @param {Function} callback
 */
export function onRecaptchaTokenLengthEvent (callback) {
  bindOn(recaptchaTokenLengthEvent, callback)
}
