import createPersistedState from 'vuex-persistedstate'

/**
 * This plugin enables syncing the store using cookies.
 * When users refresh the page, or access url directly, we can now receive the client store and use this on server side.
 *
 * @param {object} context
 * @param {object} context.store
 * @param {object} context.env
 * @param {boolean} context.isHMR
 */
export default ({ store, env, isHMR }) => {
  // In case of HMR, mutation occurs before nuxtReady, so previously saved state
  // gets replaced with original state received from server. So, we've to skip HMR.
  // Also, nuxtReady event fires for HMR as well, which results multiple registrations of vuex-persistedstate plugin
  if (isHMR) { return }

  createPersistedState({
    key: env.concept,
    paths: ['configuration', 'lead.data', 'service', 'referral', 'version', 'context.visitorId', 'experiments.override']
  })(store)
}
