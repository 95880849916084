<template>
  <footer class="footer lg:border-t">
    <div class="footer__top">
      <div class="container px-0 lg:hidden lg-down:max-w-full">
        <div class="border-b">
          <FooterAccordion
            v-for="column in columns"
            :key="column.title"
            :title="column.title"
            :links="column.links"
          />
        </div>
      </div>

      <div class="container py-10 lg-down:max-w-full">
        <div class="flex flex-wrap">
          <div class="flex-grow order-1 lg:order-0 w-full lg:w-1/3 pr-4">
            <BackToLandingPageLink id="footer-logo-link">
              <InlineSvg class="footer__logo" :class="logoColorClass" :src="logoSrc" :alt="concept" />
            </BackToLandingPageLink>
            <p class="text-xs text-gray-700 mt-1">
              {{ $t('global.receiveUpToSixQuotes') }}
            </p>
          </div>

          <div class="hidden lg:block w-full lg:w-2/3 footer__links order-0 lg:order-1">
            <div class="flex flex-wrap">
              <div
                v-for="column in columns"
                :key="column.title"
                class="link sm:w-1/3 px-4"
              >
                <h2 class="link__title text-lg mb-2 font-normal">
                  {{ column.title }}
                </h2>
                <ul class="link__list text-xs">
                  <li
                    v-for="link in column.links"
                    :key="link.text"
                    class="link__item"
                  >
                    <nuxt-link :to="link.to">
                      {{ link.text }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot />

    <div class="footer__bottom bg-gray-100 py-6 text-xs text-gray-600">
      <div class="container lg-down:max-w-full">
        <div class="flex flex-wrap sm-down:flex-col-reverse">
          <div class="flex-grow sm-down:mt-2">
            © {{ new Date().getFullYear() }} <a :href="$t('global.companyLink')" target="_blank">Bambelo B.V.</a>
          </div>

          <div class="flex flex-wrap sm-down:flex-col sm-down:space-y-2 text-left md:text-right">
            <nuxt-link :to="$t('global.privacyPolicyLink')">
              {{ $t('global.privacyPolicy') }}
            </nuxt-link>
            <span class="mx-1 sm-down:hidden">|</span>
            <nuxt-link :to="$t('global.cookiePolicyLink')">
              {{ $t('global.cookiePolicy') }}
            </nuxt-link>
            <span class="mx-1 sm-down:hidden">|</span>
            <nuxt-link :to="$t('global.termsAndConditionsLink')">
              {{ $t('global.termsAndConditions') }}
            </nuxt-link>
            <template v-if="country === 'de'">
              <span class="mx-1 sm-down:hidden">|</span>
              <nuxt-link :to="$t('global.imprintLink')">
                {{ $t('global.imprint') }}
              </nuxt-link>
            </template>
            <hr class="md:hidden opacity-50 border-gray-600 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import usesFeatureFlag from 'chimera/all/mixins/usesFeatureFlag'
import BackToLandingPageLink from 'chimera/all/themes/blueflow/components/layout/BackToLandingPageLink'
import FooterAccordion from './FooterAccordion'

export default {
  name: 'Footer',

  components: {
    BackToLandingPageLink,
    FooterAccordion,
    InlineSvg
  },

  mixins: [
    usesFeatureFlag
  ],

  props: {
    logoSrc: {
      type: String,
      default: 'images/logo.svg'
    },

    logoColorClass: {
      type: String,
      default: 'text-secondary'
    },

    columns: {
      type: Array,
      default: () => [
        {
          title: 'Over ons',
          links: [
            {
              text: 'Contact',
              to: '/contact-opnemen'
            }
          ]
        },
        {
          title: 'Professionals',
          links: [
            {
              text: 'Aanmelden als pro',
              to: '/contact-opnemen-bedrijf'
            }
          ]
        }
      ]
    }
  },

  /**
   * @returns {{concept: string}}
   */
  data () {
    return {
      concept: process.env.concept
    }
  },

  computed: {
    /**
     * @returns {*}
     */
    country () {
      return this.$store.getters['context/get']('country')
    }
  }
}
</script>
