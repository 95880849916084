<template>
  <button
    v-track-click
    :class="[colorVariant]"
    class="t-btn"
    type="button"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <span class="t-btn__body">
      <slot />
    </span>
  </button>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TBtn',

  mixins: [
    usesColor
  ],

  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
