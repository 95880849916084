<template>
  <nuxt-link :id="id" v-track-click :to="landingPageUrl">
    <slot />
  </nuxt-link>
</template>

<script>

export default {
  name: 'BackToLandingPageLink',

  props: {
    /**
     * @type {string}
     */
    id: {
      type: String,
      required: false,
      default: undefined
    }
  },

  computed: {
    /**
     * @returns {*}
     */
    landingPageUrl () {
      return this.$store.getters['configuration/getLandingPage']('url')
    }
  }
}
</script>
